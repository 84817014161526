import React, {memo} from 'react';
import {Tooltip} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
export default memo(function ({title, tip}) {
  return (
    <>
      <Tooltip className="rate-tip" placement="bottom" title={tip}>
        <QuestionCircleOutlined />
      </Tooltip>
      &nbsp;{title}
    </>
  );
});

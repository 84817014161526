import i18n from 'i18next';
import {useCallback} from 'react';
import {initReactI18next, useTranslation} from 'react-i18next';

import en from './languages/en.json';
import zh_cn from './languages/zh_cn.json';
import {
  getLocalLanguage,
  LANGUAGE,
  LOCAL_LANGUAGE_LIST,
  DEFAULT_LANGUAGE,
} from './config';

import moment from 'moment';
import './moment/zh-cn';
const resources = {en, zh_cn};
function initLanguage() {
  let lng = DEFAULT_LANGUAGE;

  //Get whether the language has been set locally
  const v = localStorage.getItem(LANGUAGE);
  if (v && LOCAL_LANGUAGE_LIST.includes(v)) {
    lng = v;
    //Get browser language
  } else if (getLocalLanguage() === 'zh') {
    lng = LOCAL_LANGUAGE_LIST[0];
  }

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng,

      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
  moment.locale(lng.replace('_', '-'));
}
initLanguage();
export function useLanguage(): [string, Function] {
  const {i18n} = useTranslation();
  const changeLanguage = useCallback(
    (value) => {
      if (i18n.language !== value && LOCAL_LANGUAGE_LIST.includes(value)) {
        moment.locale(value.replace('_', '-'));
        i18n.changeLanguage(value);
        localStorage.setItem(LANGUAGE, value);
      }
    },
    [i18n],
  );
  return [i18n.language, changeLanguage];
}
export default i18n;

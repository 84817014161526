import API, {API_REQ_TYPES} from './api';
import myServer from './server/myServer';
import server from './server';

interface RequestType extends API_REQ_TYPES {
  getFetchRequest: () => Promise<string | object>;
  postFetchRequest: () => Promise<string | object>;
}

/**
 * @method getFetchRequest
 * @param {string} url
 * @param {object} config
 * @return Promise
 */
function getFetchRequest(url: string, config = {}) {
  return new Promise((resolve, reject) => {
    server
      .get(url, config)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error));
  });
}
function postFetchRequest(url: string, params: any, config = {}) {
  const Param = params && params._parts.length ? params : '';
  return new Promise((resolve, reject) => {
    server
      .post(url, Param, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => reject(error));
  });
}

myServer.parseRouter('api', API);

export default {
  getFetchRequest,
  postFetchRequest,
  ...myServer.api,
} as RequestType;

import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import config from '../common/config';
import request from '../common/request';
import {useInterval} from '../hooks'
//actions
const SET_LENDING_TOKEN = 'SET_LENDING_TOKEN';
const SET_TOKEN_PRICE = 'SET_TOKEN_PRICE';

const INITIAL_STATE = {};

const IncomeContext = createContext();

export function useIncomeContext() {
  return useContext(IncomeContext);
}

//reducer
function reducer(state, {type, payload}) {
  switch (type) {
    case SET_LENDING_TOKEN: {
      const {lendingToken} = payload;
      return Object.assign({}, state, {lendingToken});
    }
    case SET_TOKEN_PRICE: {
      const {token} = payload;
      return Object.assign({}, state, {
        tokens: Object.assign({}, state?.tokens || {}, token),
      });
    }
    default:
      throw Error(
        `Unexpected action type in IncomeContext reducer: '${type}'.`,
      );
  }
}

export default function Provider({children}) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const fetchLendingToken = useCallback(async () => {
    const r = await request.getAllToken();
    dispatch({
      type: SET_LENDING_TOKEN,
      payload: {
        lendingToken: r,
      },
    });
  }, []);
  const fetchTokenPrice = useCallback(async (symbol) => {
    try {
      const r = await request.getTokenPrice(`?symbol=${symbol}`);
      const {price, symbol: token} = r;
      if (price && symbol) {
        dispatch({
          type: SET_TOKEN_PRICE,
          payload: {
            token: {[token]: price},
          },
        });
      }
    } catch (error) {
      console.log(error, '===fetchTokenPrice');
    }
  }, []);
  useInterval(() => {
    fetchLendingToken();
  }, config.FETCH_TIME);
  return (
    <IncomeContext.Provider
      value={useMemo(() => [{...state}, {fetchTokenPrice}], [
        state,
        fetchTokenPrice,
      ])}>
      {children}
    </IncomeContext.Provider>
  );
}

import {useTranslation} from 'react-i18next';
const useRadioOptions = () => {
  const {t} = useTranslation('radio');
  return [
    {label: t('All'), value: 'all', category: 0},
    {label: t('Lending'), value: 'Lending', category: 1},
    {label: t('DEXes'), value: 'DEXes', category: 2},
    {label: t('Derivatives'), value: 'Derivatives', category: 3},
    {label: t('Payments'), value: 'Payments', category: 4},
    {label: t('Assets'), value: 'Assets', category: 5},
  ];
};
const useMarketOptions = () => {
  const {t} = useTranslation();
  return [{label: t('Market Cap'), value: 'Market Cap', symbol: 'USD'}];
};
const useRevenueOptions = () => {
  const {t} = useTranslation();
  return [{label: t('Revenue'), value: 'Revenue', symbol: 'USD'}];
};
export {useRadioOptions, useMarketOptions, useRevenueOptions};

import {Col, Row, Tabs} from 'antd';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import utils from '../../common/utils';
import {RadioTextBox} from '../../components';
import {useInfoHistories, useProjectByName} from '../../hooks/rankings';
import {useLanguage} from '../../i18n';
import {useTIME_OPTIONS, USD_SYMBOL} from '../common/config';
import {CardItem, NoData} from '../components';
import RateItem from '../components/RateItem';
import {useMarketOptions, useRevenueOptions} from '../Home/config';
import InfoCharts from './components/InfoCharts';
import InfoTable from './components/InfoTable';
import TokenStats from './components/TokenStats';
import V from '../../common/utils/valueUtils';
import './styles.less';
import KEY from '../../common/constant/KEY';
import {CHAIN} from '../../common/constant';
const {TabPane} = Tabs;
const deCurrent = {label: 'TVL(USD)', value: 'USD', symbol: 'USD'};
const deCurrentTime = '90';
const DeRadarInfo = () => {
  const {projectId} = useParams();
  const timeOptions = useTIME_OPTIONS();
  const project = useProjectByName(projectId);
  const {t} = useTranslation(['de-radar info', 'common', 'translation']);
  const [language] = useLanguage();
  const marketOptions = useMarketOptions();
  const revenueOptions = useRevenueOptions();
  const {name, chain, website} = project || {};
  const token = V.getProjectValue(project, KEY.Token);
  const [histories, listHistories, fetchHistories] = useInfoHistories(
    projectId,
  );
  const VLKey = utils.getVLSymbol(project);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const textOptions = useMemo(() => utils.getInfoOptions(project), [
    project,
    t,
  ]);
  const [current, setCurrentUnit] = useState(deCurrent);
  const {
    value: currentUnit,
    symbol: currentSymbol,
    label: currentLabel,
  } = current;
  const [currentTime, setCurrentTime] = useState(deCurrentTime);

  useEffect(() => {
    VLKey && fetchHistories(deCurrentTime, VLKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(VLKey), fetchHistories]);
  const onCurrentUnit = useCallback((item) => {
    item?.value && setCurrentUnit(item);
  }, []);
  const onCurrentTimeChange = useCallback(
    (item) => {
      fetchHistories(item?.value, VLKey);
      item?.value && setCurrentTime(item?.value);
    },
    [VLKey, fetchHistories],
  );
  const info = useMemo(() => {
    const [dec, man] =
      language === 'en'
        ? [
            V.getProjectValue(project, KEY.DescriptionEN),
            V.getProjectValue(project, KEY.ManualEN),
          ]
        : [
            V.getProjectValue(project, KEY.DescriptionZH),
            V.getProjectValue(project, KEY.ManualZH),
          ];
    if (dec) {
      return (
        <div className="de-radar-info-faq-container">
          <h2>{t('What is name', {name})}</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: utils.HTMLDecode(dec),
            }}></span>
          <h2>{t('How to use name', {name})}</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: utils.HTMLDecode(man),
            }}></span>
        </div>
      );
    }
  }, [language, project, t, name]);
  const charts = useMemo(() => {
    const {name} = project || {};
    return (
      <div className="overview-charts overview-info-charts">
        <div className="overview-charts-charts-box overview-info-charts-box">
          <h3>
            {t(
              currentSymbol === USD_SYMBOL
                ? 'common:total value locked in'
                : 'common:locked in',
              {
                currentSymbol,
                value: name,
              },
            )}
          </h3>
          <div className="overview-radio overview-info-overview-radio">
            {textOptions ? (
              <RadioTextBox
                value={currentUnit}
                onChange={onCurrentUnit}
                options={[
                  ...(textOptions ?? []),
                  ...(V.getMarketCap(project) > 0 ? marketOptions : []),
                  ...(V.getDayRevenue(project) > 0 ? revenueOptions : []),
                ]}
              />
            ) : null}
            <RadioTextBox
              value={currentTime}
              options={timeOptions}
              onChange={onCurrentTimeChange}
            />
          </div>
          <InfoCharts
            currentLabel={currentLabel}
            histories={histories}
            currentUnit={currentUnit}
            currentSymbol={currentSymbol}
          />
          <div className="de-radar-info-info-pc">{info}</div>
        </div>
        <TokenStats project={project} />
        <div className="de-radar-info-info-mobile">{info}</div>
      </div>
    );
  }, [
    currentLabel,
    currentSymbol,
    currentTime,
    currentUnit,
    histories,
    info,
    marketOptions,
    onCurrentTimeChange,
    onCurrentUnit,
    project,
    revenueOptions,
    t,
    textOptions,
    timeOptions,
  ]);

  const statusMemo = useMemo(() => {
    const {links} = project || {};
    return (
      <Row justify="space-between" className="de-radar-info-status-box">
        <Col className="de-radar-info-key-status">
          <h3>{t('Key Stats')}</h3>
          <Row>
            <Col className="de-radar-info-status">
              <CardItem
                title={'TVL'}
                details={
                  <RateItem
                    symbol={USD_SYMBOL}
                    {...V.getTotalValueLocked(project, 'USD')}
                  />
                }
              />
              <CardItem
                title={t('P/E')}
                details={utils.tableValue(V.getPE(project), 4)}
              />
              <CardItem
                title={t('ROTA')}
                details={utils.getRota(
                  V.getDayRevenue(project),
                  V.getTVL(project, 'USD'),
                )}
              />
            </Col>
            <Col className="de-radar-info-status">
              <CardItem
                title={t('translation:Market Cap')}
                details={utils.usdValue(V.getMarketCap(project))}
              />
              <CardItem
                title={t('24h Volume')}
                details={
                  <RateItem
                    symbol={USD_SYMBOL}
                    value={V.getDayVolume(project)}
                    relative1Day={V.getDayVolumeRate(project)}
                  />
                }
              />
              <CardItem
                title={t('24h Revenue')}
                details={
                  <RateItem
                    symbol={USD_SYMBOL}
                    value={V.getDayRevenue(project)}
                    relative1Day={V.getDayRevenueRate(project)}
                  />
                }
              />
            </Col>
            {token ? (
              <Col className="de-radar-info-info">
                <CardItem
                  title={`${token} ${t('Total Supply')}`}
                  details={utils.unitConverter(V.getTotalSupply(project))}
                />
                <CardItem
                  title={`${token} ${t('Circulating Supply')}`}
                  details={utils.unitConverter(V.getCirculatingSupply(project))}
                />
                <CardItem
                  title={`${token} ${t('translation:Price')}`}
                  details={
                    <RateItem
                      price
                      value={V.getTokenPrice(project)}
                      relative1Day={V.getTokenPriceRate(project)}
                    />
                  }
                />
              </Col>
            ) : (
              ''
            )}
          </Row>
        </Col>
        {!!links && (
          <Col className="de-radar-info-links">
            <h3>{t('Ecosystem Links')}</h3>
            {links?.map((item, index) => {
              return (
                <div key={index}>
                  <a
                    key={index}
                    href={item.url}
                    rel="no-referrer"
                    target="_blank">
                    {item.description}
                  </a>
                  <br />
                </div>
              );
            })}
          </Col>
        )}
      </Row>
    );
  }, [project, t, token]);
  const tabsMemo = useMemo(() => {
    return (
      <div className="info-tabs">
        <Tabs defaultActiveKey="1" type="card" size="default">
          <TabPane tab={t('Overview')} key="1">
            {charts}
          </TabPane>
          <TabPane tab={t('Historical Data')} key="2">
            <InfoTable
              listHistories={listHistories}
              currentSymbol={currentSymbol}
              onCurrentUnit={onCurrentUnit}
              textOptions={textOptions}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }, [charts, currentSymbol, listHistories, onCurrentUnit, t, textOptions]);
  if (project === 404) {
    return <NoData />;
  }
  return (
    <div className="de-radar-info">
      <div className="de-radar-info-name">
        <h1>
          {name}&nbsp;
          {!!token && (
            <span className="de-radar-info-title-token">{token}</span>
          )}
        </h1>
        <span>
          {CHAIN[chain]}&nbsp;/&nbsp;
          <a href={website} rel="no-referrer" target="_blank">
            {utils.getWebsite(website)}
          </a>
        </span>
      </div>
      {statusMemo}
      {tabsMemo}
    </div>
  );
};
export default DeRadarInfo;

export default {
  Lending: [
    {title: 'Aave', href: 'https://aave.com/'},
    {title: 'Akropolis', href: 'https://akropolis.io/'},
    {title: 'Atomic Loans', href: 'https://atomic.loans/'},
    {title: 'bZx', href: 'https://bzx.network/'},
    {title: 'Centrifuge', href: 'https://centrifuge.io/'},
    {title: 'Compound', href: 'https://compound.finance/'},
    {title: 'Cream.Finance', href: 'http://cream.finance/'},
    {title: 'DeFiner', href: 'https://definer.org/'},
    {title: 'Fortube', href: 'https://for.tube/'},
    {title: 'Kava', href: 'http://kava.io/'},
    {title: 'Maker', href: 'https://makerdao.com/en/'},
    {title: 'Sashimi Lending', href: 'https://sashimi.cool/lending/#/'},
    {title: 'Swap Rate', href: 'https://swaprate.finance/'},
  ],
  Trading: [
    {title: 'Augur', href: 'https://www.augur.net/'},
    {title: 'ACO', href: 'https://aco.finance/'},
    {title: 'Balancer', href: 'https://balancer.finance/'},
    {title: 'Bancor', href: 'https://www.bancor.network/'},
    {title: 'DeversiFi', href: 'https://www.deversifi.com/'},
    {title: 'DEX AG', href: 'https://dex.ag/'},
    {title: 'DEXTF', href: 'https://dextf.com/'},
    {title: 'Dodo', href: 'https://dodoex.io/'},
    {title: 'dYdX', href: 'https://dydx.exchange/'},
    {title: 'Gnosis Protocol', href: 'https://docs.gnosis.io/protocol/'},
    {title: 'Hegic', href: 'https://www.hegic.co/'},
    {title: 'Helena', href: 'https://helena.network/'},
    {title: 'Idex', href: 'https://idex.io/'},
    {title: 'Jelly Swap', href: 'https://jelly.market/'},
    {title: 'KyberSwap', href: 'https://kyberswap.com/'},
    {title: 'Leverj', href: 'https://leverj.io/'},
    {title: 'Local Ethereum', href: 'https://localethereum.com/'},
    {title: 'Loopring DEX', href: 'https://loopring.io/'},
    {title: 'Market Protocol', href: 'https://marketprotocol.io/'},
    {title: 'MCDEX', href: 'https://mcdex.io/'},
    {title: 'MerkleX', href: 'https://merklex.io/'},
    {title: 'Nuo Network', href: 'https://www.nuo.network/'},
    {title: 'PerlinX', href: 'https://perlinx.finance/'},
    {title: 'Perpetual Protocol', href: 'https://perp.fi/'},
    {title: 'Ren', href: 'https://renproject.io/'},
    {title: 'SashimiSwap', href: 'https://sashimi.cool/'},
    {title: 'Set Protocol', href: 'https://www.setprotocol.com/'},
    {title: 'Sushiswap', href: 'https://sushiswap.org/'},
    {title: 'Synthetix', href: 'https://synthetix.io/'},
    {title: 'Tokenlon', href: 'https://tokenlon.im/'},
    {title: 'UMA', href: 'https://umaproject.org/'},
    {title: 'Uniswap', href: 'https://uniswap.io/'},
    {title: 'Veridex', href: 'http://dex.verisafe.io/'},
  ],
  Payments: [
    {title: 'Flexa', href: 'https://flexa.network/'},
    {title: 'Fuse', href: 'https://fuse.io/'},
    {title: 'Request Network', href: 'https://request.network/'},
  ],
  Wallets: [
    {title: 'Alpha Wallet', href: 'https://alphawallet.com/'},
    {title: 'Argent', href: 'https://www.argent.xyz/'},
    {title: 'Ash', href: 'https://ash.finance/'},
    {title: 'Atomex', href: 'https://atomex.me/'},
    {title: 'Coinbase Wallet', href: 'https://wallet.coinbase.com/'},
    {title: 'DEXWallet', href: 'https://www.dexwallet.io/'},
    {title: 'Eidoo', href: 'https://eidoo.io/'},
    {title: 'Infinity Wallet', href: 'https://infinitywallet.io/'},
    {title: 'Math Wallet', href: 'http://mathwallet.net/'},
    {title: 'Meet.One', href: 'http://meet.one/'},
    {title: 'Monolith', href: 'https://monolith.xyz/'},
    {title: 'My Crypto', href: 'https://mycrypto.com/'},
    {title: 'My Ether Wallet', href: 'https://www.myetherwallet.com/'},
    {title: 'MyKey', href: 'https://mykey.org/'},
    {title: 'Gnosis Safe', href: 'https://safe.gnosis.io/'},
    {title: 'HB Wallet', href: 'https://www.hb-wallet.com/'},
    {title: 'Pillar Wallet', href: 'https://pillarproject.io/'},
    {title: 'Poketto', href: 'https://poketto.cash/#/'},
  ],
  Interfaces: [
    {title: 'Bamboo Relay', href: 'https://bamboorelay.com/'},
    {title: 'Dca.land', href: 'https://www.dca.land/#'},
    {title: 'DDEX', href: 'https://ddex.io/margin/ETH-DAI'},
    {title: 'DeBank', href: 'https://debank.com/'},
    {title: 'DeFi Saver', href: 'https://defisaver.com/'},
    {title: 'DeFi Snap', href: 'https://www.defisnap.io/'},
    {title: 'dForce Network', href: 'https://dforce.network/'},
    {title: 'Dharma', href: 'https://www.dharma.io/'},
    {title: 'EasyCDP', href: 'https://easycdp.com/'},
    {title: 'FiatDex Gateway',href: 'https://github.com/happypsyduck/fiatdex-gateway'},
    {title: 'Frontier', href: 'https://frontierwallet.com/'},
    {title: 'Harvest Finance', href: 'https://harvest.finance/'},
    {title: 'InstaDApp', href: 'https://instadapp.io/'},
    {title: 'Stabilize Protocol', href: 'https://www.stabilize.finance/'},
    {title: 'yearn finance', href: 'http://yearn.finance/'},
    {title: 'Melon', href: 'https://melonport.com/'},
    {title: 'Totle', href: 'https://www.totle.com/'},
    {title: 'Unspent', href: 'https://unspent.io/'},
    {title: 'Zerion', href: 'https://zerion.io/'},
  ],
  Infrastructure: [
    {title: '0x', href: ''},
    {title: 'Betoken', href: 'https://betoken.fund/'},
    {title: 'DFOhub', href: 'https://dfohub.com/'},
    {title: 'EPNS', href: 'https://epns.io/'},
    {title: 'Graph Protocol', href: 'https://thegraph.com/'},
    {title: 'Nest', href: 'https://nestprotocol.org/'},
    {title: 'Nexus Mutual', href: 'https://nexusmutual.io/'},
    {title: 'Opyn', href: 'https://opyn.co/'},
    {title: 'PhishFort Protect', href: 'https://phishfort.com/protect'},
    {title: 'pToken', href: 'https://ptokens.io/'},
    {title: 'Tokentax', href: 'https://tokentax.co/'},
  ],
  Assets: [
    {title: 'Ampleforth', href: 'https://www.ampleforth.org/'},
    {title: 'Augmint', href: 'https://www.augmint.org/'},
    {title: 'DAI', href: 'https://makerdao.com/'},
    {title: 'Metronome', href: 'https://metronome.io/'},
    {title: 'mStable', href: 'https://mstable.org/'},
    {title: 'Neutral', href: 'https://neutralproject.com/'},
    {title: 'rDAI', href: 'https://rdai.money/'},
    {title: 'Reserve', href: 'https://www.reserve.org/'},
    {title: 'USDx', href: 'https://dforce.network/'},
    {title: 'WBTC', href: 'https://www.wbtc.network/'},
  ],
  Scaling: [
    {title: 'Bloxroute', href: 'https://www.bloxroute.com/'},
    {title: 'Celer', href: 'https://celer.network/'},
    {title: 'Connext', href: 'https://connext.network/'},
    {title: 'Lightning Network', href: 'https://lightning.network/'},
    {title: 'Liquidity Network', href: 'https://liquidity.network/'},
    {title: 'Loom Network', href: 'https://loomx.io/'},
    {title: 'Loopring', href: 'https://loopring.org/'},
    {title: 'xDai', href: 'xdai'},
  ],
  Analytics: [
    {title: '0x Tracker', href: 'https://0xtracker.com/'},
    {title: 'Coin Interest Rate', href: 'https://coininterestrates.com/'},
    {title: 'DefiScan', href: 'https://defiscan.io/'},
    {title: 'Etherscan', href: 'https://etherscan.io/'},
    {title: 'Eth Gas Station', href: 'https://ethgasstation.info/'},
    {title: 'Loan Scan', href: 'https://loanscan.io/'},
    {title: 'UniswapROI', href: 'https://www.uniswaproi.com/#'},
    {title: 'Whois0x', href: 'https://whois0x.io/'},
  ],
  Education: [
    {title: 'DeFi Nerd', href: 'https://www.de-radar-nerd.com/'},
    {title: 'DeFi News', href: 'https://definews.co/'},
    {title: 'DeFi Prime', href: 'https://defiprime.com/'},
    {title: 'DeFi Rate', href: 'https://defirate.com/'},
    {title: 'EthHub Weekly Newsletter', href: 'https://ethhub.io/'},
  ],
  Podcasts: [
    {title: 'Chris Blec', href: 'https://youtube.com/c/chrisblec'},
    {title: 'Into the Ether Podcast', href: 'https://ethhub.substack.com/'},
    {title: 'Wyre Podcast', href: 'https://blog.sendwyre.com/wyratelks/home'},
  ],
  Newsletters: [
    {title: 'Bankless', href: 'https://bankless.substack.com/'},
    {title: 'DeFi Tutorial', href: 'https://defitutorials.substack.com/'},
    {title: 'DeFi Value', href: 'https://defivalue.substack.com/'},
    {title: 'DeFi Weekly', href: 'http://defiweekly.substack.com/'},
    {title: 'Dose of DeFi', href: 'https://mailchi.mp/b95fc066ba08/doseofdefi'},
    {title: 'EthHub Weekly Newsletter', href: 'https://ethhub.substack.com/'},
    {title: 'The Defiant', href: 'https://thedefiant.substack.com/'},
  ],
  Communities: [
    {title: 'Concourse Open Community', href: 'https://concourseopen.com/'},
    {title: 'Dai para principiantes',href: 'https://daiparaprincipiantes.com/'},
    {title: 'DeFi Nation', href: 'https://joindefination.com/'},
    {title: 'Ethereum Italia', href: 'https://www.ethereum-italia.it/'},
    {title: 'Hola DeFi', href: 'https://holadefi.com/'},
    {title: 'ProstoCoin', href: 'https://prostocoin.io/'},
  ],
};

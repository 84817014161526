import {Spin} from 'antd';
import React, {memo} from 'react';
import './styles.less';
function Loading() {
  return (
    <div className="de-radar-loading-box">
      <Spin className="de-radar-loading-spin" size="large" />
    </div>
  );
}
export default memo(Loading);

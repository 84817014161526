import {Table} from 'antd';
import React, {useMemo} from 'react';
import utils from '../../../../common/utils';
import './styles.less';
import {RightOutlined} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import lendingUtils from '../../../../common/utils/lendingUtils';
import {useTranslation} from 'react-i18next';
import config from '../../config';
const IncomeTable = (props) => {
  const history = useHistory();
  const {t} = useTranslation('income');
  const {dataSource, type, stakingTime} = props;
  const columns = useMemo(() => {
    return [
      {
        fixed: 'left',
        className: 'income-table-left',
        title: t('Source'),
        dataIndex: 'Source',
        render: (text, item) => {
          return <span>{item?.name}</span>;
        },
      },
      {
        title: t('Income APY'),
        dataIndex: 'APR',
        render: (text, item) => {
          return (
            <span className="income-table-apr">
              {utils.getPercentage(item?.incomeAPY)}
            </span>
          );
        },
      },
      {
        title: t('Income Days', {stakingTime}),
        dataIndex: 'Income',
        render: (text, item) => {
          return (
            <span className="income-table-apr-month">
              ${utils.unitConverter(item?.totalIncomeValue)}
              <br />
              <span className="income-table-apr-font-grey">
                {utils.unitConverter(item?.totalIncome)} {item.assets}
              </span>
            </span>
          );
        },
      },
      ...(type === config.incomeType.Lending
        ? [
            {
              title: t('Payout APY'),
              dataIndex: 'Payout APY',
              render: (text, item) => {
                return (
                  <span className="income-table-apr">
                    {utils.getPercentage(item?.payoutAPY)}
                  </span>
                );
              },
            },
            {
              title: t('Payout Days', {stakingTime}),
              dataIndex: 'Payout',
              render: (text, item) => {
                return (
                  <span className="income-table-apr-month">
                    ${utils.unitConverter(item?.totalPayoutValue)}
                    <br />
                    <span className="income-table-apr-font-grey">
                      {utils.unitConverter(item?.totalPayout)} {item.assets}
                    </span>
                  </span>
                );
              },
            },
          ]
        : []),
      {
        dataIndex: 'next',
        render: (text, item) => {
          return (
            <a
              rel="no-referrer"
              target="_blank"
              onClick={(e) => e.stopPropagation()}
              href={item?.website}
              className="income-table-apr-next">
              {t('Start Earning')}
              <RightOutlined />
            </a>
          );
        },
      },
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, dataSource]);
  return (
    <Table
      loading={!dataSource}
      scroll={{x: 800}}
      columns={columns}
      dataSource={dataSource}
      pagination={{pageSize: 20, hideOnSinglePage: true}}
      onRow={(item) => {
        return {
          onClick: () => {
            history.push(`/${lendingUtils.getProjectId(item.id)}`);
          },
        };
      }}
    />
  );
};
export default IncomeTable;

import server from './index';

function myServer()  {}
/**
 * @method parseRouter
 * @param  {string} name
 * @param  {object} urlObj
 */
myServer.prototype.parseRouter = function (name: string, urlObj: any) {
  const obj: any = (this[name] = {});
  Object.keys(urlObj).forEach((item) => {
    obj[item] = this.send.bind(this, urlObj[item]);
  });
};
/**
 * @method send
 * @param  {string} url
 * @param  {object|string} config
 * @return Promise
 */
myServer.prototype.send = function (url: string, config: any) {
  const {type = 'get', data = {}} = config || {};
  if (type === 'get') {
    return new Promise((resolve, reject) => {
      const getUrl = typeof config === 'string' ? url + config : url;
      server
        .get(getUrl, {
          params: data,
        })
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  } else {
    return new Promise((resolve, reject) => {
      server
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => reject(error));
    });
  }
};

export default new (myServer as any)();

import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import config from '../common/config';
import KEY_KIT from '../common/constant/KEY_KIT';
import request from '../common/request';
import {useInterval} from '../hooks';
import V from '../common/utils/valueUtils';
import utils from '../common/utils';
//actions
const SET_PROJECTS = 'SET_PROJECTS';
const SET_HISTORIES = 'SET_HISTORIES';
const INITIAL_STATE = {};

const HomeContext = createContext();

export function useHomeContext() {
  return useContext(HomeContext);
}

//reducer
function reducer(state, {type, payload}) {
  switch (type) {
    case SET_PROJECTS: {
      return Object.assign({}, state, payload);
    }
    case SET_HISTORIES: {
      return Object.assign({}, state, payload);
    }
    default:
      throw Error(`Unexpected action type in HomeContext reducer: '${type}'.`);
  }
}

export default function Provider({children}) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const fetchProjects = useCallback(async () => {
    try {
      const projects = await request.getAllProject();
      const {totalMarketCap} = utils.getTotal(projects);
      dispatch({
        type: SET_PROJECTS,
        payload: {
          lendingProjects: V.getLendingProject(projects),
          projects,
          totalMarketCap,
        },
      });
    } catch (error) {
      console.log(error, '======home-fetchProjects');
    }
  }, []);

  const fetchHistories = useCallback(async (category, length) => {
    let cat = `&category=${category}`;
    let len = length && length !== 'all' ? `&length=${length}` : '';
    try {
      const r = await request.getSnapshotList(
        `?SnapshotKeys=${KEY_KIT.HOME_CHART_KEY.join(',')}&${cat}${len}`,
      );
      dispatch({
        type: SET_HISTORIES,
        payload: {
          histories: r?.snapshots,
        },
      });
    } catch (error) {
      console.log(error, '===fetchHistories');
    }
  }, []);
  useInterval(() => {
    fetchProjects();
  }, config.FETCH_TIME);
  return (
    <HomeContext.Provider
      value={useMemo(() => [{...state}, {fetchHistories}], [
        state,
        fetchHistories,
      ])}>
      {children}
    </HomeContext.Provider>
  );
}

import React from 'react';
import './styles.less';
const CardItem = (props) => {
  const {title, details} = props;
  return (
    <div className="card-item">
      <span className="card-item-title">{title}</span>
      {Array.isArray(details) ? (
        details.map((item, index) => {
          return <span key={index}>{item || '-'}</span>;
        })
      ) : (
        <span>{details || '-'}</span>
      )}
    </div>
  );
};
export default CardItem;

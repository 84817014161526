import KEY from '../constant/KEY';

//Module
function getSummaryValue(i: any, key: string, s?: string) {
  return i?.latestSnapshot?.[`${KEY.SummarySnapshot}_${key}${s ? `_${s}` : ''}`]
    ?.value;
}
function getHistoriesValue(i: any, key: string, s?: string) {
  return i?.[`${KEY.SummarySnapshot}_${key}${s ? `_${s}` : ''}`];
}
function getProjectValue(i: any, key: string) {
  return i?.extraProperties?.[`${KEY.Project}_${key}`];
}

function getCategoryTVL(i: any, s: string) {
  return i?.[`${KEY.CategorySnapshot}_${KEY.TVL}${s ? `_${s}` : ''}`];
}
function getCategoryVL(i: any, s: string) {
  return i?.[`${KEY.CategorySnapshot}_${KEY.VL}${s ? `_${s}` : ''}`];
}
//Key
function getTVL(i: any, s: string) {
  return i?.latestSnapshot
    ? getSummaryValue(i, KEY.TVL, s)
    : getHistoriesValue(i, KEY.TVL, s);
}
function getVL(i: any, s: string) {
  return i?.latestSnapshot
    ? getSummaryValue(i, KEY.VL, s)
    : getHistoriesValue(i, KEY.VL, s);
}
function getVLRate(i: any, s: string) {
  return i?.latestSnapshot
    ? getSummaryValue(i, KEY.VLRate, s)
    : getHistoriesValue(i, KEY.VLRate, s);
}
function getTVLRate(i: any, s: string) {
  return i?.latestSnapshot
    ? getSummaryValue(i, KEY.TVLRate, s)
    : getHistoriesValue(i, KEY.TVLRate, s);
}
function getTotalValueList(i: any) {
  let list: any = [];
  Object.entries(i?.latestSnapshot || {}).forEach(
    ([key, value]: [string, any]) => {
      if (key.includes(`${KEY.SummarySnapshot}_${KEY.TVL}_`)) {
        const symbol = key.split('_')[2];
        list.push({
          symbol,
          value: value?.value,
          relative1Day: getTVLRate(i, symbol),
        });
      }
    },
  );
  return list;
}
function getLendingProject(projects: any) {
  let list: any = [];
  Array.isArray(projects) &&
    projects.forEach((i) => {
      const bool = Object.keys(i.latestSnapshot || {}).find((k) =>
        k.includes(`${KEY.LendingProject}_`),
      );
      if (bool) list.push(i);
    });
  return list;
}
function getLendingKey(symbol: any) {
  return [
    `SummarySnapshot_Lending_IncomeAPY_${symbol}`,
    `SummarySnapshot_Lending_PayoutAPY_${symbol}`,
  ];
}
function getTotalValueLocked(i: any, s: string) {
  return {
    value: getTVL(i, s),
    relative1Day: getTVLRate(i, s),
  };
}
function getValueLocked(i: any, s: string) {
  return {
    value: getVL(i, s),
    relative1Day: getVLRate(i, s),
  };
}

//SummarySnapshot
function getPE(i: any) {
  return getSummaryValue(i, KEY.PE);
}
function getMarketCap(i: any) {
  return i?.latestSnapshot
    ? getSummaryValue(i, KEY.MarketCap)
    : getHistoriesValue(i, KEY.MarketCap);
}
function getMarketCapRate(i: any) {
  return getSummaryValue(i, KEY.MarketCapRate);
}
function getTokenPrice(i: any) {
  return getSummaryValue(i, KEY.TokenPrice);
}
function getTokenPriceRate(i: any) {
  return getSummaryValue(i, KEY.TokenPriceRate);
}
function getDayRevenue(i: any) {
  return i?.latestSnapshot
    ? getSummaryValue(i, KEY.DayRevenue)
    : getHistoriesValue(i, KEY.DayRevenue);
}
function getDayRevenueRate(i: any) {
  return getSummaryValue(i, KEY.DayRevenueRate);
}
function getDayVolume(i: any) {
  return i?.latestSnapshot
    ? getSummaryValue(i, KEY.DayVolume)
    : getHistoriesValue(i, KEY.DayVolume);
}
function getWeekVolume(i: any) {
  return getSummaryValue(i, KEY.WeekVolume);
}
function getDayVolumeRate(i: any) {
  return getSummaryValue(i, KEY.DayVolumeRate);
}
function getMarketCapDominance(i: any) {
  return getSummaryValue(i, KEY.MarketCapDominance);
}
function getTotalSupply(i: any) {
  return getSummaryValue(i, KEY.TotalSupply);
}
function getMaxSupply(i: any) {
  return getSummaryValue(i, KEY.MaxSupply);
}
function getCirculatingSupply(i: any) {
  return getSummaryValue(i, KEY.CirculatingSupply);
}
function getDayTokenIssuance(i: any) {
  return getSummaryValue(i, KEY.DayTokenIssuance);
}
function getMonthRevenue(i: any) {
  return getSummaryValue(i, KEY.MonthRevenue);
}
function getYearRevenue(i: any) {
  return getSummaryValue(i, KEY.YearRevenue);
}
function getIncomeAPY(i: any, m: string, s: string) {
  return i?.latestSnapshot
    ? getSummaryValue(i, `${m}_${KEY.IncomeAPY}`, s)
    : getHistoriesValue(i, `${m}_${KEY.IncomeAPY}`, s);
}
function getPayoutAPY(i: any, m: string, s: string) {
  return i?.latestSnapshot
    ? getSummaryValue(i, `${m}_${KEY.PayoutAPY}`, s)
    : getHistoriesValue(i, `${m}_${KEY.PayoutAPY}`, s);
}
function getLendingIncomeAPY(i: any, s: string) {
  return i?.latestSnapshot
    ? getSummaryValue(i, KEY.Lending_IncomeAPY, s)
    : getHistoriesValue(i, KEY.Lending_IncomeAPY, s);
}
function getLendingPayoutAPY(i: any, s: string) {
  return i?.latestSnapshot
    ? getSummaryValue(i, KEY.Lending_PayoutAPY, s)
    : getHistoriesValue(i, KEY.Lending_PayoutAPY, s);
}
function getOutstanding(i: any, s: string) {
  return i?.latestSnapshot
    ? getSummaryValue(i, KEY.Outstanding, s)
    : getHistoriesValue(i, KEY.Outstanding, s);
}
//Key

export default {
  //Module
  getSummaryValue,
  getHistoriesValue,
  getProjectValue,
  getCategoryTVL,
  getCategoryVL,
  getIncomeAPY,
  getPayoutAPY,
  //SummarySnapshot
  getPE,
  getVL,
  getTVL,
  getVLRate,
  getTVLRate,
  getDayVolume,
  getDayRevenue,
  getMarketCap,
  getMaxSupply,
  getWeekVolume,
  getYearRevenue,
  getTokenPrice,
  getMonthRevenue,
  getTotalSupply,
  getValueLocked,
  getDayRevenueRate,
  getMarketCapRate,
  getDayVolumeRate,
  getTokenPriceRate,
  getTotalValueList,
  getDayTokenIssuance,
  getTotalValueLocked,
  getCirculatingSupply,
  getMarketCapDominance,
  getLendingIncomeAPY,
  getLendingPayoutAPY,
  getOutstanding,
  //Key
  getLendingKey,
  getLendingProject,
};

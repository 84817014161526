import {Input} from 'antd';
import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import utils from '../../common/utils';
import V from '../../common/utils/valueUtils';
import {OverviewCard, RadioBox, RadioTextBox} from '../../components';
import {useHomeContext} from '../../contexts/homeContext';
import {USD_SYMBOL, useTIME_OPTIONS} from '../common/config';
import FaqContainer from './components/FaqContainer';
import HomeCharts from './components/HomeCharts';
import HomeTable from './components/HomeTable';
import {useRadioOptions} from './config';
import './styles.less';
const deCurrentTime = '90';
const deRadioValue = 0;
const deCurrent = {label: 'TVL(USD)', value: 'USD', symbol: 'USD'};
const Home = () => {
  const {t} = useTranslation(['home', 'common', 'radio']);
  const [{projects, histories}, {fetchHistories}] = useHomeContext();

  const timeOptions = useTIME_OPTIONS();
  const radioOptions = useRadioOptions();
  const [radio, setRadioValue] = useState(radioOptions[0]);
  const {value: radioValue, category: radioCategory} = radio;
  const [search, setSearch] = useState();
  const [current, setCurrentUnit] = useState(deCurrent);
  const {value: currentUnit, symbol: currentSymbol} = current;
  const [currentTime, setCurrentTime] = useState(deCurrentTime);

  const textOptions = useMemo(() => {
    return utils.getHomeOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
  useEffect(() => {
    fetchHistories(deRadioValue, deCurrentTime);
  }, [fetchHistories]);
  const arrSort = useCallback(
    (a, b, c) => {
      const bV =
        currentSymbol === 'USD'
          ? V.getTVL(b, currentSymbol)
          : V.getVL(b, currentSymbol);
      if (isNaN(bV) || bV === 0) return c === 'descend' ? 1 : -1;
      const aV =
        currentSymbol === 'USD'
          ? V.getTVL(a, currentSymbol)
          : V.getVL(a, currentSymbol);
      return bV - aV;
    },
    [currentSymbol],
  );
  const allProject = useMemo(() => {
    if (Array.isArray(projects)) {
      if (radioCategory === 0) {
        return projects
          .map((item, index) => {
            return {
              ...item,
              key: index,
            };
          })
          .sort(arrSort);
      } else {
        return projects
          .filter((item) => item?.category === radioCategory)
          .map((item, index) => {
            return {
              ...item,
              key: index,
            };
          })
          .sort(arrSort);
      }
    }
  }, [projects, radioCategory, arrSort]);
  const overviewData = useMemo(() => {
    return utils.getHomeOverviewData(allProject, histories);
  }, [allProject, histories]);
  const onRadioChange = useCallback(
    (item) => {
      fetchHistories(item?.category, currentTime);
      item && setRadioValue(item);
    },
    [currentTime, fetchHistories],
  );
  const onCurrentUnit = useCallback((item) => {
    item?.value && setCurrentUnit(item);
  }, []);
  const onCurrentTimeChange = useCallback(
    (item) => {
      fetchHistories(radioCategory, item?.value);
      item?.value && setCurrentTime(item?.value);
    },
    [fetchHistories, radioCategory],
  );
  const locked = useMemo(() => {
    let title = t('common:Total Value Locked');
    let details = utils.unitConverter(overviewData?.[currentSymbol]);
    if (currentUnit !== deCurrent.value) {
      title = t('common:locked');
    } else {
      details = '$' + details;
    }
    return (
      <OverviewCard title={`${title} (${currentSymbol})`} details={details} />
    );
  }, [currentSymbol, currentUnit, overviewData, t]);
  const Overview = useMemo(() => {
    return (
      <>
        <h1>{t('home title')}</h1>
        <div className="overview">
          <div className="overview-left-box">
            {locked}
            <OverviewCard
              title={`${overviewData?.name || ''} ${t('common:Dominance')}`}
              details={`${utils.digits(overviewData?.percent, 2)}%`}
            />
          </div>
          <div className="overview-charts">
            <div className="overview-charts-charts-box">
              <h1>
                {t(
                  currentSymbol === USD_SYMBOL
                    ? 'common:total value locked in'
                    : 'common:locked in',
                  {
                    currentSymbol,
                    value:
                      radioValue === 'all'
                        ? 'DeFi'
                        : t(`radio:${t(radioValue)}`),
                  },
                )}
              </h1>
              <div className="overview-radio">
                <RadioTextBox
                  value={currentUnit}
                  onChange={onCurrentUnit}
                  options={textOptions}
                />
                <RadioTextBox
                  value={currentTime}
                  options={timeOptions}
                  onChange={onCurrentTimeChange}
                />
              </div>
              <HomeCharts
                histories={histories}
                currentUnit={currentUnit}
                currentSymbol={currentSymbol}
              />
            </div>
          </div>
        </div>
      </>
    );
  }, [
    t,
    locked,
    overviewData?.name,
    overviewData?.percent,
    currentSymbol,
    radioValue,
    currentUnit,
    onCurrentUnit,
    textOptions,
    currentTime,
    timeOptions,
    onCurrentTimeChange,
    histories,
  ]);
  const onSearch = useCallback((e) => {
    if (typeof e === 'string') {
      setSearch(e);
    } else {
      setSearch(e.target.value);
    }
  }, []);
  const searchProjects = useMemo(() => {
    if (search && Array.isArray(allProject)) {
      return allProject.filter(
        (item) =>
          utils.includes(item.name, search) ||
          utils.includes(item.token, search),
      );
    } else {
      return allProject;
    }
  }, [allProject, search]);

  return (
    <div>
      {Overview}
      <div className="home-radio-search">
        <RadioBox
          options={radioOptions}
          value={radioValue}
          onChange={onRadioChange}
        />
        <Input.Search
          placeholder={t('common:search')}
          className="home-search"
          onSearch={onSearch}
          onChange={onSearch}
        />
      </div>
      <HomeTable
        textOptions={textOptions}
        category={radioCategory}
        options={searchProjects}
        currentSymbol={currentSymbol}
        onCurrentUnit={onCurrentUnit}
      />
      <FaqContainer />
    </div>
  );
};

export default Home;

import {BigNumber} from 'bignumber.js';
import moment from 'moment';
import {hideRadioOptions} from '../../pages/Lending/config';
import i18n from 'i18next';
import V from './valueUtils';
import KEY from '../constant/KEY';
import {USD_SYMBOL} from '../../pages/common/config';
const BUnit = 1000000000;
const MUnit = 1000000;
const KUnit = 1000;

const digits = (count: number | BigNumber, num = 2) => {
  if (count === undefined || count === null) {
    return '';
  }

  const SCount = String(count);
  const floatPart = SCount.split('.')[1];
  if (floatPart && floatPart.length > num) {
    return new BigNumber(count).toFixed(num);
  }
  if (BigNumber.isBigNumber(count)) {
    return SCount;
  }
  return count;
};
const priceDigits = (count: number | BigNumber, num = 4) => {
  if (count === undefined || count === null) return '-';
  return digits(count, num);
};
const getClassName = (de: string, incoming: string | Array<string>) => {
  if (incoming) {
    if (Array.isArray(incoming) && incoming.length) {
      return `${de} ${incoming.join(' ')}`;
    }
    return `${de} ${incoming}`;
  }
  return de;
};
const getListLast = (list: any) => {
  if (Array.isArray(list)) {
    return list[list.length - 1];
  }
};
const unitDigits = (count: number | BigNumber, num = 2) => {
  if (count === undefined || count === null) {
    return '';
  }
  const SCount = String(count);
  const floatPart = SCount.split('.')[1];
  if (floatPart && floatPart.length >= num) {
    return new BigNumber(count).toFixed(num);
  } else {
    return new BigNumber(count).toFixed(1);
  }
};
const unitConverter = (num: number) => {
  if (isNaN(num)) {
    return '-';
  }
  num = Number(num);
  if (Math.abs(num) > BUnit) {
    return unitDigits(new BigNumber(num).dividedBy(BUnit), 3) + 'B';
  } else if (Math.abs(num) > MUnit) {
    return unitDigits(new BigNumber(num).dividedBy(MUnit), 2) + 'M';
  } else if (Math.abs(num) > KUnit) {
    return unitDigits(new BigNumber(num).dividedBy(KUnit), 1) + 'K';
  } else {
    if (num === 0) {
      return '0';
    }
    return unitDigits(new BigNumber(num), 1);
  }
};
const getHomeOptions = () => {
  return [
    {label: `TVL(USD)`, value: 'USD', symbol: 'USD'},
    {label: `BTC ${i18n.t('common:locked')}`, value: 'BTC', symbol: 'BTC'},
    {label: `ETH ${i18n.t('common:locked')}`, value: 'ETH', symbol: 'ETH'},
  ];
};
const getLendingOptions = (list: any) => {
  let options: any = [],
    radioOptions: any = [],
    obj: any = {};
  Array.isArray(list) &&
    list.forEach((item) => {
      Object.keys(item?.latestSnapshot || {}).forEach((k) => {
        if (
          k.includes(`${KEY.LendingProject}_${KEY.IncomeAPY}`) ||
          k.includes(`${KEY.LendingProject}_${KEY.PayoutAPY}`)
        ) {
          const arr = k.split('_');
          const symbol = getListLast(arr);
          obj[symbol] = [symbol];
        }
      });
    });
  Object.keys(obj || {}).forEach((symbol) => {
    radioOptions.push({
      label: symbol,
      value: symbol,
      symbol,
    });
    if (!hideRadioOptions.includes(symbol)) {
      options.push({
        label: symbol,
        value: symbol,
        symbol,
      });
    }
  });
  return [options, radioOptions];
};
const getOptions = (list: any) => {
  let options;
  if (Array.isArray(list)) {
    console.log(list,'=====list');
    
    const last = getListLast(list);
    if (last) {
      options = [];
      options.push({label: `TVL(USD)`, value: 'USD', symbol: 'USD'});
      Object.entries(last?.valueLocked || {}).forEach(
        ([key, value]: [any, any]) => {
          if (key !== 'USD' && value?.value > 0) {
            options.push({
              label: key.toUpperCase() + ' ' + i18n.t('common:locked'),
              value: key,
              symbol: key.toUpperCase(),
            });
          }
        },
      );
    }
  }
  return options;
};
const getVLKey = (info: any) => {
  let keys: any = [];
  Object.keys(info?.latestSnapshot || {}).forEach((i) => {
    if (i.includes(`${KEY.SummarySnapshot}_${KEY.VL}_`)) {
      const key = i.split('_')[2];
      if (key !== USD_SYMBOL) {
        keys.push(i);
      }
    }
  });
  return keys;
};
const getVLSymbol = (info: any) => {
  let symbol: any = [];
  Object.keys(info?.latestSnapshot || {}).forEach((i) => {
    if (i.includes(`${KEY.SummarySnapshot}_${KEY.VL}_`)) {
      const key = i.split('_')[2];
      if (key !== USD_SYMBOL) {
        symbol.push(key);
      }
    }
  });
  return symbol;
};
const getInfoOptions = (info: any) => {
  let options: any = [];
  options.push({label: `TVL(USD)`, value: 'USD', symbol: 'USD'});
  getVLSymbol(info).forEach((key: any) => {
    options.push({
      label: key.toUpperCase() + ' ' + i18n.t('common:locked'),
      value: key,
      symbol: key.toUpperCase(),
    });
  });
  return options;
};
const getHistoriesRate = (lendingHistories: any, name: string) => {
  if (lendingHistories) {
    const a = lendingHistories[0]?.interestSpeed?.[name]?.USD;
    const b = lendingHistories[1]?.interestSpeed?.[name]?.USD;
    return (a - b) / b;
  }
};
const getPercentage = (num: number) => {
  const str = digits(num, 2);
  if (str) {
    return str + '%';
  }
  return '<0.01%';
};
const getWebsite = (url: string) => {
  if (typeof url === 'string') {
    url = url.replace('https://', 'www.').replace('/', '');
  }
  return url;
};
const getLendingChartKey = (key: string) => {
  if (typeof key === 'string') {
    key = key.toUpperCase().replace(/_/g, '.').replace('-', ' ');
  }
  return key;
};
const getMillisecond = (time: any) => {
  const {seconds} = time || {};
  let tim = seconds || time;
  if (String(tim).length <= 10) {
    return tim * 1000;
  }
  if (typeof tim !== 'number') {
    return Number(tim);
  }
  return tim;
};
const getSecond = (time: any) => {
  if (String(time).length > 10) {
    return Math.ceil(time / 1000);
  }
  return time;
};
const formatTime = (
  time: moment.MomentInput | string,
  format: string = 'lll',
) => {
  const s = String(time);
  if (!s.includes('T')) {
    return moment(getMillisecond(s)).format(format);
  }
  return moment(time).format(format);
};
function getChartsTime(time: moment.MomentInput | string) {
  return getSecond(time);
}
const HTMLDecode = (text: string) => {
  if (!text) {
    return '';
  }
  let temp = document.createElement('div');
  temp.innerHTML = text;
  return temp.innerText || temp.textContent;
};
function isChinese(temp: string) {
  var re = /[^\u4E00-\u9FA5]/;
  if (re.test(temp)) return false;
  return true;
}
function includes(str: string, search: string) {
  return (
    typeof str === 'string' &&
    typeof search === 'string' &&
    str.toLowerCase().includes(search.toLowerCase())
  );
}
function usdValue(v: any) {
  if (isNaN(v) || v === 0) return '-';
  return '$' + unitConverter(v);
}
function tableValue(v: any, num: number) {
  if (isNaN(v) || v === 0) return '-';
  if (num) {
    return digits(v, num);
  }
  return v;
}
function formatPercent(v: any, num = 2) {
  if (isNaN(v) || v === 0) return '-';
  return digits(v * 100, num) + '%';
}
function percentValue(v: any, num = 2) {
  if (isNaN(v) || v === 0) return '-';
  return digits(v, num) + '%';
}
function getRotaValue(revenue: number, tvl: number) {
  const rota = ((revenue * 365) / tvl) * 100;
  return rota;
}
function getRota(revenue: number, tvl: number) {
  return percentValue(getRotaValue(revenue, tvl));
}
function chartsItem(v: any) {
  if (typeof v === 'string') v = Number(v);
  if (isNaN(v) || v === Infinity) return 0;
  return v;
}
function priceValue(v: any, num = 4) {
  if (isNaN(v) || v === 0) return '-';
  return '$' + priceDigits(v, num);
}
function lendingSorter(a: number, b: number, c: string) {
  if (!a) return c === 'descend' ? -1 : 1;
  if (!b) return c === 'descend' ? 1 : -1;
  return a - b;
}
function homeSorter(a: number, b: number, c: string) {
  if (isNaN(b)) return c === 'descend' ? 1 : -1;
  return a - b;
}
function getHomeOverviewData(list: any, histories: any) {
  if (Array.isArray(list)) {
    const TVL_USD = V.getCategoryTVL(histories, 'USD');
    const VL_ETH = V.getCategoryVL(histories, 'ETH');
    const VL_BTC = V.getCategoryVL(histories, 'BTC');
    const top1 = list[0];
    let USD = 0;
    list.forEach((i) => {
      const vUSD = V.getTVL(i, 'USD');
      if (!isNaN(vUSD)) {
        USD = USD + vUSD;
      }
    });
    if (top1) {
      return {
        USD: getListLast(TVL_USD)?.value,
        ETH: getListLast(VL_ETH)?.value,
        BTC: getListLast(VL_BTC)?.value,
        ...top1,
        percent: (V.getTVL(top1, 'USD') / USD) * 100,
      };
    }
  }
}
function getTotal(list: any) {
  let totalMarketCap = 0;
  Array.isArray(list) &&
    list.forEach((i) => {
      const marketCap = V.getMarketCap(i);
      if (!isNaN(marketCap)) totalMarketCap += marketCap;
    });
  return {totalMarketCap};
}
function isToday(str: string) {
  if (new Date(str).toDateString() === new Date().toDateString()) {
    return true;
  }
}
export default {
  digits,
  getRota,
  usdValue,
  includes,
  getVLKey,
  isChinese,
  formatTime,
  tableValue,
  HTMLDecode,
  chartsItem,
  getWebsite,
  homeSorter,
  getListLast,
  priceDigits,
  priceValue,
  getOptions,
  getVLSymbol,
  percentValue,
  getRotaValue,
  getClassName,
  getChartsTime,
  lendingSorter,
  unitConverter,
  getPercentage,
  formatPercent,
  getInfoOptions,
  getHomeOptions,
  getTotal,
  isToday,
  getHistoriesRate,
  getLendingOptions,
  getLendingChartKey,
  getHomeOverviewData,
};

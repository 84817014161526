import React from 'react';
import './styles.less';
import utils from '../../common/utils';
const OverviewCard = (props) => {
  const {title, details, className} = props;
  return (
    <div className={utils.getClassName('overview-card-box', className)}>
      <h2>{title}</h2>
      <span className = 'overview-card-box-span'>{details}</span>
    </div>
  );
};
export default OverviewCard;

import V from './valueUtils';
interface supplyType {
  dominanceName: string;
  dominancePercent: string;
}
interface interestSpeed {
  dominanceName: string;
  dominancePercent: string;
}
interface marketData {
  name: string;
  totalInterestSpeed: string;
  totalSupply: string;
  supply: supplyType;
  interestSpeed: interestSpeed;
}
const getLendingOverviewData = (
  lendingMarketData: Array<marketData>,
  chartRadio: string,
  radioValue: string,
) => {
  if (lendingMarketData && Array.isArray(lendingMarketData)) {
    const data = lendingMarketData.find((item) => item?.name === radioValue);
    if (chartRadio === 'outstanding') {
      return {
        total: data?.totalSupply,
        dominanceName: data?.supply?.dominanceName,
        dominancePercent: data?.supply?.dominancePercent,
      };
    } else {
      return {
        total: data?.totalInterestSpeed,
        dominanceName: data?.interestSpeed?.dominanceName,
        dominancePercent: data?.interestSpeed?.dominancePercent,
      };
    }
  }
};

const getProjectId = (name: string) => {
  return name.toLowerCase().replace(' ', '-');
};
const getMAXLend = (list: any, radioValue: string) => {
  if (Array.isArray(list)) {
    let item: any;
    list.forEach((i) => {
      const lend = V.getLendingIncomeAPY(i, radioValue);
      if (lend && (!item || item < lend)) item = lend;
    });
    return item;
  }
};
const getMINBorrow = (list: any, radioValue: string) => {
  if (Array.isArray(list)) {
    let item: any;
    list.forEach((i) => {
      const borrow = V.getLendingPayoutAPY(i, radioValue);
      if (borrow && (!item || item > borrow)) item = borrow;
    });
    return item;
  }
};
const getInterestSpeed = (item: any) => {
  return item?.interestSpeed?.Total?.value;
};
const getOutstanding = (item: any) => {
  return item?.outstanding?.Total?.valueUSD?.value;
};
const getIPY = (outstanding: any, borrow: any) => {
  return outstanding * borrow;
};
export default {
  getIPY,
  getLendingOverviewData,
  getProjectId,
  getMINBorrow,
  getMAXLend,
  getInterestSpeed,
  getOutstanding,
};

import {useTranslation} from 'react-i18next';
const USD_SYMBOL = 'USD';
const CUP = ['🏆', '🥈', '🥉'];
const useTIME_OPTIONS = () => {
  const {t} = useTranslation('time');
  return [
    {label: t('All'), value: 'all'},
    {label: t('1 Year'), value: '360'},
    {label: t('90 Day'), value: '90'},
    {label: t('30 Day'), value: '30'},
  ];
};
export {CUP, USD_SYMBOL, useTIME_OPTIONS};

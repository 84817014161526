import React, {useMemo} from 'react';
import {Dropdown, Menu, Table} from 'antd';
import utils from '../../../../common/utils';
import './styles.less';
import {useTranslation} from 'react-i18next';
import {TooltipTitle, TableRateItem} from '../../../components';
import {USD_SYMBOL} from '../../../common/config';
import {CaretDownOutlined} from '@ant-design/icons';
import V from '../../../../common/utils/valueUtils';
const arrSort = (a, b) => {
  if (!a?.timestamp) return -1;
  return b?.timestamp - a.timestamp;
};
const InfoTable = (props) => {
  const {listHistories, currentSymbol, onCurrentUnit, textOptions} = props;
  const {t} = useTranslation([
    'de-radar info',
    'common',
    'translation',
    'token stats',
  ]);
  const dataSource = useMemo(() => {
    let obj = {};
    Object.entries(listHistories || {}).forEach(([k, list]) => {
      if (!Array.isArray(list)) return;

      //Compare whether there is the same data of the day
      let less = 1;
      if (utils.isToday(list[list.length - 2]?.timestamp)) less = 2;

      for (let i = list.length - less; i >= 0; i--) {
        const e = list[i];
        const {timestamp} = e;

        if (!obj[timestamp]) obj[timestamp] = {latestSnapshot: {}};

        if (!obj[timestamp].timestamp) {
          obj[timestamp].key = timestamp;
          obj[timestamp].timestamp = timestamp;
        }

        if (!obj[timestamp].latestSnapshot[k])
          obj[timestamp].latestSnapshot[k] = {};
        obj[timestamp].latestSnapshot[k] = e;
      }
    });
    return Object.values(obj).sort(arrSort);
  }, [listHistories]);
  const TableMenu = useMemo(() => {
    return (
      <Menu
        onClick={(item) => {
          onCurrentUnit?.(textOptions[item.key]);
        }}>
        {textOptions?.map((item, index) => {
          return <Menu.Item key={index}>{item.label}</Menu.Item>;
        })}
      </Menu>
    );
  }, [onCurrentUnit, textOptions]);
  const thDropdown = useMemo(() => {
    let text = currentSymbol;
    if (currentSymbol === USD_SYMBOL) {
      text = `${t('common:locked')}(${currentSymbol})`;
    }
    return (
      <Dropdown overlay={TableMenu} trigger={['click']}>
        <span
          className="home-table-dropdown-a"
          onClick={(e) => e.preventDefault()}>
          {text} <CaretDownOutlined />
        </span>
      </Dropdown>
    );
  }, [TableMenu, currentSymbol, t]);
  const columns = useMemo(
    () => [
      {
        title: t('Date'),
        fixed: 'left',
        className: 'info-table-date',
        dataIndex: 'date',
        render: (text, item) => {
          return utils.formatTime(item?.timestamp, 'YYYY-MM-DD');
        },
      },
      {
        width: 130,
        key: 'locked',
        title: () => {
          return thDropdown;
        },
        render: (text, item) => {
          const value =
            currentSymbol === 'USD'
              ? V.getTotalValueLocked(item, currentSymbol)
              : V.getValueLocked(item, currentSymbol);
          if (!value) return '-';
          return <TableRateItem symbol={currentSymbol} {...value} />;
        },
      },
      {
        title: t('translation:Market Cap'),
        key: 'Market Cap',
        render: (text, item) => {
          return (
            <TableRateItem
              noConversion
              value={utils.usdValue(V.getMarketCap(item))}
              relative1Day={V.getMarketCapRate(item)}
            />
          );
        },
      },
      {
        title: t('translation:Price'),
        key: 'Price',
        render: (text, item) => {
          return (
            <TableRateItem
              noConversion
              value={utils.priceValue(V.getTokenPrice(item))}
              relative1Day={V.getTokenPriceRate(item)}
            />
          );
        },
      },
      {
        title: t('24h Revenue'),
        key: '24h Revenue',
        render: (text, item) => {
          return (
            <TableRateItem
              noConversion
              value={utils.usdValue(V.getDayRevenue(item))}
              relative1Day={V.getDayRevenueRate(item)}
            />
          );
        },
      },
      {
        title: t('24h Volume'),
        key: '24h Volume',
        render: (text, item) => {
          return (
            <TableRateItem
              noConversion
              value={utils.usdValue(V.getDayVolume(item))}
              relative1Day={V.getDayVolumeRate(item)}
            />
          );
        },
      },
      {
        title: (
          <TooltipTitle
            title={t('P/E')}
            tip={t('token stats:P/E (Price to Earning) Tip')}
          />
        ),
        key: '24h Volume',
        render: (text, item) => {
          return utils.tableValue(V.getPE(item), 4);
        },
      },
      {
        title: (
          <TooltipTitle title={t('ROTA')} tip={t('token stats:ROTA Tip')} />
        ),
        key: 'ROTA',
        render: (text, item) => {
          return utils.getRota(V.getDayRevenue(item), V.getTVL(item, 'USD'));
        },
      },
    ],
    [currentSymbol, t, thDropdown],
  );

  return (
    <Table
      scroll={{x: 900}}
      columns={columns}
      loading={!dataSource}
      className="info-table"
      dataSource={dataSource}
    />
  );
};
export default InfoTable;

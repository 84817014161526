import React from 'react';
import image from '../../../assets/image';
import {useHistory} from 'react-router-dom';
export default function Logo({className}) {
  const history = useHistory();
  return (
    <img
      onClick={() => history.push('/')}
      className={className}
      src={image.DeRadar}></img>
  );
}

import {Dropdown, Menu, Table} from 'antd';
import React, {useMemo} from 'react';
import {CaretDownOutlined} from '@ant-design/icons';
import utils from '../../../../common/utils';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import V from '../../../../common/utils/valueUtils';
import lendingUtils from '../../../../common/utils/lendingUtils';
const Span = (props) => {
  const {children} = props;
  if (children && children > 0) {
    return utils.percentValue(children, 4);
  }
  return '-';
};
const LockedSpan = (props) => {
  const {children, s} = props;
  if (children && children > 0) {
    return (s || '') + utils.unitConverter(children);
  }
  return '-';
};
const arrSort = (a, b) => {
  if (!b.name) return -1;
  return a.name?.localeCompare(b?.name);
};
const LendingTable = (props) => {
  const history = useHistory();
  const {t} = useTranslation('lending radio', 'table', 'lending');
  const {options, onCurrentUnit, radioValue, textOptions, radioSymbol} = props;
  const TableMenu = useMemo(() => {
    return (
      <div className="dropdown-menu">
        <Menu
          onClick={(item) => {
            onCurrentUnit?.(textOptions[item.key]);
            item.domEvent.stopPropagation();
          }}>
          {textOptions?.map((item, index) => {
            return (
              <Menu.Item key={index}>
                {t('IPY')} {item.symbol}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    );
  }, [onCurrentUnit, t, textOptions]);
  const thDropdown = useMemo(() => {
    return (
      <div className="text-center">
        <Dropdown overlay={TableMenu}>
          <span
            className="home-table-dropdown-a"
            onClick={(e) => e.preventDefault()}>
            {t('IPY')}({radioSymbol}) <CaretDownOutlined />
          </span>
        </Dropdown>
      </div>
    );
  }, [TableMenu, radioSymbol, t]);
  const columns = useMemo(() => {
    return [
      {
        title: '#',
        fixed: 'left',
        key: '#',
        dataIndex: 'date',
        className: 'de-radar-date',
        render: (text, item, index) => {
          return <span className="de-radar-emoji">{index + 1}.</span>;
        },
      },
      {
        fixed: 'left',
        className: 'de-radar-name',
        title: t('table:name'),
        key: 'Name',
        render: (text, item) => {
          return item.name;
        },
      },
      {
        title: t('lending:Lend%'),
        key: 'Lend',
        render: (text, item) => {
          return <Span>{item.lend}</Span>;
        },
        sorter: (a, b, c) => utils.lendingSorter(a?.lend, b?.lend, c),
      },
      {
        title: t('lending:Borrow%'),
        key: 'Borrow',
        render: (text, item) => {
          return <Span>{item.borrow}</Span>;
        },
        sorter: (a, b, c) => utils.lendingSorter(a?.borrow, b?.borrow, c),
      },
      {
        title: t('Outstanding'),
        key: 'Outstanding',
        render: (text, item) => (
          <LockedSpan s="$">{item.outstanding}</LockedSpan>
        ),
        sorter: (a, b, c) =>
          utils.lendingSorter(a?.outstanding, b?.outstanding, c),
      },
      {
        key: 'locked',
        title: () => {
          return thDropdown;
        },
        render: (text, item) => {
          return <LockedSpan s="$">{item.ipy}</LockedSpan>;
        },
        sorter: (a, b, c) => utils.lendingSorter(a?.ipy, b?.ipy, c),
      },
    ];
  }, [t, thDropdown]);
  const list = useMemo(() => {
    let list;
    if (Array.isArray(options)) {
      list = options.map((item, index) => {
        // const {outstanding, ipy} = item;
        const outstanding = V.getOutstanding(item, radioValue)
        const borrow = V.getLendingPayoutAPY(item, radioValue)
        return {
          ...item,
          key: index,
          lend: V.getLendingIncomeAPY(item, radioValue),
          borrow,
          outstanding,
          ipy: lendingUtils.getIPY(outstanding,borrow),
        };
      });
      list.sort(arrSort);
    }
    return list;
  }, [options, radioValue]);
  return (
    <Table
      loading={!list}
      pagination={false}
      scroll={{x: 750}}
      columns={columns}
      dataSource={list}
      showSorterTooltip={false}
      onRow={(record) => {
        return {
          onClick: () => history.push(`/${record.id}`),
        };
      }}
    />
  );
};
export default LendingTable;

import React, {useEffect} from 'react';
import echarts from 'echarts/lib/echarts';
import {Spin} from 'antd';
export {echarts};
import './styles.less';
export default function ECharts({id = 'echarts-main', loading}) {
  useEffect(() => {
    const myChart = echarts.init(document.getElementById(id));
    const resize = () => myChart.resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [id]);
  return (
    <div style={{position: 'relative'}}>
      {loading && <Spin className="eharts-loading" size="large" />}
      <div id={id} className="eharts-box" />
    </div>
  );
}

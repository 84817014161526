import {Tabs} from 'antd';
import React from 'react';
import './styles.less';
import IncomePage from './IncomePage';
import {useTranslation} from 'react-i18next';
import config from './config';
const pages = [
  'Lending',
  // 'Swap', 'Vault',
  'Farm',
];
const Income = () => {
  const {t} = useTranslation('income');
  return (
    <>
      <h1>{t('Calculate Your Earnings')}</h1>
      <Tabs defaultActiveKey="Lending" type="card" size="default">
        {pages.map((i) => {
          return (
            <Tabs.TabPane tab={t(i)} key={i}>
              <IncomePage module={i} type={config.incomeType[i]} />
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </>
  );
};

export default Income;

import {Layout} from 'antd';
import React from 'react';
import Logo from '../components/Logo';
import './styles.less';
const {Footer} = Layout;
const toolList = [
  {
    href: 'https://discord.gg/aQJRYV6RDf',
    name: 'Discord',
  },
  {
    href: 'https://t.me/DeRadar',
    name: 'Telegram',
  },
  {
    href: 'https://twitter.com/DeFi_Radar',
    name: 'Twitter',
  },
  {
    href: 'https://medium.com/de-radar',
    name: 'Medium',
  },
];
const DeRadarFooter = () => {
  return (
    <Footer className="footer">
      <div className="footer-body">
        <Logo className="footer-logo" />
        <div className="footer-tool">
          {toolList.map((item, index) => (
            <a key={index} href={item.href} rel="no-referrer" target="_blank">
              {item.name}
            </a>
          ))}
        </div>
      </div>
    </Footer>
  );
};

export default DeRadarFooter;

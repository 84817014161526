import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import request from '../common/request';
import V from '../common/utils/valueUtils';
//actions
const SET_LENDING_MARKET_DATA = 'SET_LENDING_MARKET_DATA';
const SET_LENDING_HISTORIES = 'SET_LENDING_HISTORIES';

const INITIAL_STATE = {};

const LendingContext = createContext();

export function useLendingContext() {
  return useContext(LendingContext);
}

//reducer
function reducer(state, {type, payload}) {
  switch (type) {
    case SET_LENDING_MARKET_DATA: {
      const {lendingMarketData} = payload;
      return Object.assign({}, state, {lendingMarketData});
    }
    case SET_LENDING_HISTORIES: {
      const {lending} = payload;
      const {lendingHistories} = state;
      return Object.assign({}, state, {
        lendingHistories: Object.assign({}, lendingHistories || {}, lending),
      });
    }
    default:
      throw Error(
        `Unexpected action type in LendingContext reducer: '${type}'.`,
      );
  }
}

export default function Provider({children}) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const fetchLendingHistories = useCallback(
    async (symbol, length, lendingProjects) => {
      let len = length && length !== 'all' ? `&length=${length}` : '';
      try {
        if (lendingProjects) {
          const promises = lendingProjects.map(async (i) => {
            const r = await request.getSnapshotList(
              `?ProjectId=${i.id}&SnapshotKeys=${V.getLendingKey(
                symbol,
              )}${len}`,
            );
            return {[i?.name]: r?.snapshots};
          });
          const results = await Promise.all(promises);
          dispatch({
            type: SET_LENDING_HISTORIES,
            payload: {
              lending: {[symbol]: results},
            },
          });
        }
      } catch (error) {
        console.log(error, '===fetchLendingHistories');
      }
    },
    [],
  );
  return (
    <LendingContext.Provider
      value={useMemo(() => [{...state}, {fetchLendingHistories}], [
        state,
        fetchLendingHistories,
      ])}>
      {children}
    </LendingContext.Provider>
  );
}

import React, {useMemo} from 'react';
import Nav from './Nav';
import './styles.less';
import Logo from '../components/Logo';
import {Affix} from 'antd';
import {useMobile} from '../../hooks';
const TopBar = () => {
  const isMobile = useMobile();
  const headerBody = useMemo(
    () => (
      <div className="header-body">
        <Logo className="header-logo" />
        <Nav />
      </div>
    ),
    [],
  );
  if (isMobile) {
    return (
      <Affix className="header-nav-affix">
        <header className="header header-nav-border">{headerBody}</header>
      </Affix>
    );
  }
  return <header className="header">{headerBody}</header>;
};

export default TopBar;

import enUS from 'antd/lib/locale/en_US';
import zh_CN from 'antd/lib/locale/zh_CN';
const LANGUAGE = 'LANGUAGE';
const LOCAL_LANGUAGE = [
  {language: 'zh_cn', title: '简体中文'},
  {language: 'en', title: 'English'},
];
const LOCAL_LANGUAGE_LIST = ['zh_cn', 'en'];
const DEFAULT_LANGUAGE = LOCAL_LANGUAGE_LIST[0];

const getLocalLanguage = () => {
  let lang = navigator.language || navigator.userLanguage;
  if (typeof lang === 'string') {
    lang = lang.substr(0, 2);
  }
  return lang;
};
const ANTD_LOCAL = {
  zh_cn: zh_CN,
  en: enUS,
};
export {
  LANGUAGE,
  LOCAL_LANGUAGE,
  LOCAL_LANGUAGE_LIST,
  getLocalLanguage,
  ANTD_LOCAL,
  DEFAULT_LANGUAGE,
};

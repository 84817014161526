import {Tooltip} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import utils from '../../common/utils';
import './styles.less';
const RateSpan = (props) => {
  const {t} = useTranslation();
  const {children, noTip, style, token1Day} = props;
  if (children === null || children === undefined) return '-';
  const body = () => {
    return (
      <>
        <span
          style={style}
          className={
            token1Day
              ? 'de-radar-token-day'
              : children >= 0
              ? 'de-radar-green-color'
              : 'de-radar-red-color'
          }>
          {children >= 0 && '+'}
          {utils.digits(children, token1Day ? 4 : 2)}%
        </span>
      </>
    );
  };
  if (noTip) {
    return body();
  }
  return (
    <Tooltip className="rate-tip" placement="bottom" title={t('24h change')}>
      {body()}
    </Tooltip>
  );
};
export default RateSpan;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useRef, useState} from 'react';
import config from '../common/config';
const isClient = typeof window === 'object';

/**
 * @method useFetchState
 * Prevent React state update on an unmounted component.
 */
function useFetchState<S>(initialState: S) {
  const focus = useRef<boolean>();
  const [state, setState] = useState(initialState);
  useEffect(() => {
    focus.current = true;
    return () => {
      focus.current = false;
    };
  }, []);
  const setFetchState = useCallback((value: S) => {
    focus.current && setState(value);
  }, []);
  return [state, setFetchState];
}

/**
 * @method useEffectOnce
 * @see https://github.com/streamich/react-use/blob/master/docs/useEffectOnce.md
 */
function useEffectOnce(effect: React.EffectCallback) {
  useEffect(effect, []);
}

/**
 * @method useMedia
 * React sensor hook that tracks state of a CSS media query.
 * @see https://github.com/streamich/react-use/blob/master/docs/useMedia.md
 */
function useMedia(query: string, defaultState: boolean = false) {
  const [state, setState] = useState(
    isClient ? () => window.matchMedia(query).matches : defaultState,
  );

  useEffect(() => {
    let mounted = true;
    const mql = window.matchMedia(query);
    const onChange = () => {
      if (!mounted) {
        return;
      }
      setState(!!mql.matches);
    };

    mql.addEventListener('change', onChange);
    setState(mql.matches);

    return () => {
      mounted = false;
      mql.removeEventListener('change', onChange);
    };
  }, [query]);

  return state;
}

/**
 * @method useInterval
 * @see https://github.com/streamich/react-use/blob/master/docs/useInterval.md
 */
function useInterval(callback: Function, delay?: number | undefined) {
  useEffect(() => {
    if (delay !== null && delay !== undefined) {
      callback();
      const interval = setInterval(callback, delay);
      return () => clearInterval(interval);
    }
  }, [delay]);
}
/**
 * @method useMobile
 * @return isMobile
 */
function useMobile() {
  const isMobile = useMedia(`(max-width:${config.MOBILE_WIDTH})`);
  return isMobile;
}

/**
 * @method usePrevious
 * React state hook that returns the previous state as described in the React hooks FAQ.
 * @see https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
function usePrevious<T>(state: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = state;
  });

  return ref.current;
}
export interface UseTitleOptions {
  restoreOnUnMount?: boolean;
}

const DEFAULT_USE_TITLE_OPTIONS: UseTitleOptions = {
  restoreOnUnMount: false,
};

function useTitle(
  title: string,
  options: UseTitleOptions = DEFAULT_USE_TITLE_OPTIONS,
) {
  const prevTitleRef = useRef(document.title);
  document.title = title;
  useEffect(() => {
    if (options && options.restoreOnUnMount) {
      return () => {
        document.title = prevTitleRef.current;
      };
    } else {
      return;
    }
  }, []);
}

export {
  useTitle,
  useInterval,
  useMedia,
  useFetchState,
  useEffectOnce,
  useMobile,
  usePrevious,
};

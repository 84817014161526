import React from 'react';
import {useTranslation} from 'react-i18next';
import './styles.less';
import project from './config';
const DeRadarProject = () => {
  const {t} = useTranslation(['Project', 'nav']);
  return (
    <div className="de-radar-project-content">
      <h1>{t('nav:Project')}</h1>
      {Object.entries(project || {}).map(([key, v]) => {
        return (
          <div key={key}>
            <h3>{t(key)}</h3>
            <ul>
              {v.map(({title, href}, index) => {
                return (
                  <li key={index + title}>
                    <a href={href} rel="no-referrer" target="_blank">
                      {title}
                    </a>
                    {t(title)}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default DeRadarProject;

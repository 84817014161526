import React from 'react';
import './styles.less';
import {useTranslation} from 'react-i18next';
const FaqContainer = () => {
  const {t} = useTranslation(['FAQ', 'nav']);
  return (
    <div className="faq-container">
      <h2>{t('What is DeFi Q')}</h2>
      <p>{t('What is DeFi A')}</p>
      <h2>{t('What’s the purpose of DeRadar Q')}</h2>
      <p>
        {t('What’s the purpose of DeRadar A1')}
        {/* <Link to="/de-radar-project">{t('nav:Project')}</Link>
        {t('What’s the purpose of DeRadar A2')} */}
      </p>
      <h2>{t('Disclaimer Q')}</h2>
      <p>{t('Disclaimer A')}</p>
    </div>
  );
};

export default FaqContainer;

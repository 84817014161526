import axios from 'axios';
// const baseURL = process.env.NODE_ENV;
// const baseURL = 'http://192.168.199.160:44358';
const server = axios.create({
  // baseURL: process.env.NODE_ENV === 'production' ? '' : baseURL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  timeout: 50000,
});
// // TODO: request processing
// server.interceptors.request.use((config) => {
//   return config;
// });

// // TODO: response processing
// server.interceptors.response.use((res) => {
//   return res;
// });
export default server;

import {Col, Row} from 'antd';
import React, {memo} from 'react';
import utils from '../../../../common/utils';
import {Loading, RateSpan} from '../../../../components';
import {USD_SYMBOL} from '../../../common/config';
import './styles.less';
import {useTranslation} from 'react-i18next';
import {TooltipTitle, RateItem} from '../../../components';
import V from '../../../../common/utils/valueUtils';
import KEY from '../../../../common/constant/KEY';
import {useHomeContext} from '../../../../contexts/homeContext';
const Item = ({title, details, detailsElement, className}) => {
  return (
    <Row
      className={utils.getClassName('stats-item-box', className)}
      justify="space-between">
      <span className="stats-item-title">{title}</span>
      {detailsElement ? detailsElement : <span>{details}</span>}
    </Row>
  );
};
const Item2 = ({title, detailsList}) => {
  return (
    <Col className="stats-item2">
      <span className="stats-item-title">{title}</span>
      <Col className="stats-item2-box">
        {detailsList?.map((item, index) => (
          <Item key={index} {...item} className="item2-row" />
        ))}
      </Col>
    </Col>
  );
};
const TotalItem = ({value, relative1Day, symbol, token1Day}) => {
  return (
    <span>
      {symbol === USD_SYMBOL ? '$' : ''}
      {utils.unitConverter(value)}&nbsp;&nbsp;
      <RateSpan token1Day={!isNaN(token1Day)}>{relative1Day || token1Day}</RateSpan>
    </span>
  );
};
const TokenStats = ({project}) => {
  const {t} = useTranslation(['token stats', 'common', 'translation']);
  const TVLList = V.getTotalValueList(project);
  const token = V.getProjectValue(project, KEY.Token);
  const [{totalMarketCap}] = useHomeContext();
  return (
    <div className="de-radar-info-stats">
      {!project && <Loading />}
      {!!token && (
        <Col>
          <h4>{t('Token Stats')}</h4>
          <Col className="de-radar-info-stats-box border-bottom-div">
            <Item
              title={`${token} ${t('Price (24h Change)')}`}
              detailsElement={
                <RateItem
                  price
                  value={V.getTokenPrice(project)}
                  relative1Day={V.getTokenPriceRate(project)}
                />
              }
            />
            <Item
              title={t('translation:Market Cap')}
              details={utils.usdValue(V.getMarketCap(project))}
            />
            <Item
              title={t('Market Cap Dominance')}
              details={utils.formatPercent(
                V.getMarketCap(project) / totalMarketCap,
              )}
            />
            <Item
              title={t('Total Supply')}
              details={utils.unitConverter(V.getTotalSupply(project))}
            />
            <Item
              title={t('Circulating Supply')}
              details={utils.unitConverter(V.getCirculatingSupply(project))}
            />
            <Item
              title={t('Token Additional')}
              detailsElement={
                <TotalItem
                  {...{
                    value: V.getDayTokenIssuance(project),
                    token1Day:
                      (V.getDayTokenIssuance(project) /
                        V.getCirculatingSupply(project)) *
                      100,
                  }}
                />
              }
            />
          </Col>
        </Col>
      )}
      <Col>
        <h4>{t('Protocol Stats')}</h4>
        <Col className="de-radar-info-stats-box">
          <Item2
            title={
              <TooltipTitle
                title={t('translation:Revenue')}
                tip={t('Revenue: Total fees paid')}
              />
            }
            detailsList={[
              {
                title: t('24h'),
                details: utils.usdValue(V.getDayRevenue(project)),
              },
              {
                title: t('1m'),
                details: utils.usdValue(V.getMonthRevenue(project)),
              },
              {
                title: t('1y'),
                details: utils.usdValue(V.getYearRevenue(project)),
              },
            ]}
          />
          <Item2
            title={t('Volume')}
            detailsList={[
              {
                title: t('24h'),
                details: utils.usdValue(V.getDayVolume(project)),
              },
              {
                title: t('7d'),
                details: utils.usdValue(V.getWeekVolume(project)),
              },
            ]}
          />
          <Item2
            title={t('Total Value Locked(TVL,24h Change)')}
            detailsList={TVLList?.map((v, i) => {
              return {
                title: v.symbol,
                detailsElement: <TotalItem key={i} {...v} symbol={v.symbol} />,
              };
            })}
          />
          <Col className="border-bottom-div">
            <Item
              title={`BTC ${t('common:locked')}`}
              detailsElement={
                <TotalItem {...V.getValueLocked(project, 'BTC')} />
              }
            />
            <Item
              title={`ETH ${t('common:locked')}`}
              detailsElement={
                <TotalItem {...V.getValueLocked(project, 'ETH')} />
              }
            />
            <Item
              title={
                <TooltipTitle
                  title={t('P/E (Price to Earning)')}
                  tip={t('P/E (Price to Earning) Tip')}
                />
              }
              details={utils.tableValue(V.getPE(project), 4)}
            />
            <Item
              title={<TooltipTitle title={t('ROTA')} tip={t('ROTA Tip')} />}
              details={utils.getRota(
                V.getDayRevenue(project),
                V.getTVL(project, USD_SYMBOL),
              )}
            />
          </Col>
        </Col>
      </Col>
    </div>
  );
};
export default memo(TokenStats);

import React, {useEffect} from 'react';
import utils from '../../../../common/utils';
import chartsConfig from '../../../common/chartsConfig';
import {useTranslation} from 'react-i18next';
import ECharts, {echarts} from '../../../../components/ECharts';
import {USD_SYMBOL} from '../../../common/config';
import V from '../../../../common/utils/valueUtils';
const formatter = (params) => {
  const param = params[0];
  let text = param.seriesName;
  if (param.seriesName === 'USD') {
    text = '$';
  }
  return (
    utils.formatTime(param.name) +
    '<br/>' +
    param.marker +
    text +
    ' ' +
    utils.unitConverter(param.value)
  );
};
const HomeCharts = (props) => {
  const {histories, currentUnit, currentSymbol} = props;
  const {t} = useTranslation();
  useEffect(() => {
    var myChart = echarts.init(document.getElementById('echarts-main'));
    const list =
      currentUnit === USD_SYMBOL
        ? V.getCategoryTVL(histories, currentUnit)
        : V.getCategoryVL(histories, currentUnit);
    if (list && Array.isArray(list)) {
      let data = [],
        dates = [];
      for (let i = 0, j = list.length; i < j; i++) {
        const element = list[i];
        if (!element) continue;
        dates.push(utils.getChartsTime(element.timestamp));
        data.push(utils.chartsItem(element?.value));
      }
      myChart.setOption(
        {
          color: chartsConfig.color,
          tooltip: {
            ...chartsConfig.tooltip(),
            formatter,
          },
          grid: chartsConfig.grid,
          xAxis: chartsConfig.xAxis(dates),
          yAxis: chartsConfig.yAxis,
          series: [
            {
              name: currentSymbol,
              type: 'line',
              data: data,
            },
          ],
        },
        true,
      );
    }
  }, [currentSymbol, currentUnit, histories, t]);
  return <ECharts loading={!histories} />;
};

export default HomeCharts;

import {Dropdown, Menu} from 'antd';
import React, {useEffect, useMemo, useState} from 'react';
import utils from '../../../../common/utils';
import {CaretDownOutlined} from '@ant-design/icons';
const LendingRadioBox = (props) => {
  const {options, value, onChange, textOptions} = props;
  const [radio, setRadio] = useState();
  useEffect(() => {
    if (!radio && textOptions) {
      setRadio(textOptions[0]);
    }
  }, [radio, textOptions]);

  useEffect(() => {
    if (options) {
      const item = options.find((item) => {
        return item.value === value;
      });
      if (!item) {
        const i = textOptions.find((item) => {
          return item.value === value;
        });
        if (i) {
          setRadio(i);
        }
      }
    }
  }, [options, textOptions, value]);
  const TableMenu = useMemo(() => {
    return (
      <div className="dropdown-menu">
        <Menu
          onClick={(item) => {
            onChange?.(textOptions[item.key]);
            setRadio(textOptions[item.key]);
          }}>
          {textOptions?.map((item, index) => {
            return <Menu.Item key={index}>{item.label}</Menu.Item>;
          })}
        </Menu>
      </div>
    );
  }, [onChange, textOptions]);
  return (
    <div className="radio-box">
      {options?.map((item, index) => {
        let incoming = [];
        if (item.value === value) {
          incoming.push('radio-selected');
        }
        return (
          <button
            key={index}
            onClick={() => onChange?.(item)}
            className={utils.getClassName('radio-item', incoming)}>
            {item.label}
          </button>
        );
      })}
      <Dropdown
        onClick={() => onChange?.(radio)}
        className={utils.getClassName(
          'radio-item',
          radio?.value === value ? 'radio-selected' : '',
        )}
        overlay={TableMenu}>
        <span onClick={(e) => e.preventDefault()}>
          {radio?.value} <CaretDownOutlined />
        </span>
      </Dropdown>
    </div>
  );
};

export default LendingRadioBox;

import {useCallback} from 'react';
import {useEffectOnce, useFetchState} from '.';
import KEY from '../common/constant/KEY';
import KEY_KIT from '../common/constant/KEY_KIT';
import request from '../common/request';
const useInfoHistories = (name) => {
  const [listHistories, setListHistories] = useFetchState();
  const [histories, setHistories] = useFetchState();
  const fetchInfoHistories = useCallback(
    async (length, keys) => {
      let len = length && length !== 'all' ? `&length=${length}` : '';
      try {
        let appendKey = [];
        if (Array.isArray(keys)) {
          keys.forEach((i) => {
            appendKey.push(`${KEY.SummarySnapshot}_${KEY.VL}_${i}`);
            appendKey.push(`${KEY.SummarySnapshot}_${KEY.VLRate}_${i}`);
          });
        }
        const key = !listHistories
          ? `${KEY_KIT.INFO_TABLE_KEY.join(',')},${appendKey.join(',')}`
          : `${KEY_KIT.INFO_CHART_KEY.join(',')},${appendKey.join(',')}`;
        const r = await request.getSnapshotList(
          `?ProjectId=${name}&SnapshotKeys=${key}${len}`,
        );
        setHistories(r?.snapshots);
        if (!listHistories) {
          setListHistories(r?.snapshots);
        }
      } catch (error) {
        console.log(error, '===useInfoHistories');
      }
    },
    [listHistories, name, setHistories, setListHistories],
  );
  return [histories, listHistories, fetchInfoHistories];
};

const useProjectByName = (id) => {
  const [project, setProject] = useFetchState();
  const fetch = useCallback(async () => {
    try {
      const r = await request.getProject(`/${id}`);
      setProject(r);
    } catch (error) {
      console.log(error, '======error');
      if (error.toString() === 'Error: Request failed with status code 404') {
        setProject(404);
      }
    }
  }, [id, setProject]);
  useEffectOnce(() => {
    fetch();
  });
  return project;
};
export {useProjectByName, useInfoHistories};

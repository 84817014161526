export interface API_REQ_TYPES {
  getTokenPrice: () => Promise<string | object>;
  getSnapshotList: () => Promise<string | object>;
  getSnapshotMetaData: () => Promise<string | object>;
  getAllProject: () => Promise<string | object>;
  getProject: () => Promise<string | object>;
  getAllToken: () => Promise<string | object>;
}
export default {
  getTokenPrice: '/api/app/token/price',
  //new
  getSnapshotList: '/api/app/Snapshot/list',
  getSnapshotMetaData: '/api/app/Snapshot-meta-data',
  getAllProject: '/api/app/project/all',
  getProject: '/api/app/project',
  getAllToken:'/api/app/token/all'
};

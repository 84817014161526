import utils from '../../common/utils';

const isMobile = () => {
  const {offsetWidth} = document.querySelector('body');
  return offsetWidth < 1080;
};
const grid = {
  top: '5%',
  left: '2%',
  right: '2%',
  bottom: '3%',
  containLabel: true,
};
const yAxis = {
  scale: true,
  axisTick: {
    show: false,
  },
  axisLine: {
    show: false,
  },
  axisLabel: {
    fontWeight: 'bold',
    inside: true, 
    formatter: utils.unitConverter,
    margin: -5,
    padding: [0, 0, 20, 0], 
  },
};
const xAxis = (data) => {
  // const interval = Math.ceil(data.length / (isMobile() ? 3 : 10));
  // let interval = 'auto';
  // if (intervalL) {
  //   interval = Math.ceil(data.length / intervalL);
  // }
  // if (isMobile()) {
  //   interval = Math.ceil(data.length / 3);
  // }
  return {
    data,
    type: 'category',
    boundaryGap: false,
    axisLabel: {
      showMinLabel: false,
      // showMaxLabel: false,
      interval: 'auto',
      formatter: function (value) {
        return utils.formatTime(value, 'll');
      },
    },
  };
};
export default {
  tooltip: () => {
    let position;
    // if (isMobile()) {
    //   position = function (pos, params, el, elRect, size) {
    //     let obj = {
    //       top: 30,
    //     };
    //     obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
    //     return obj;
    //   };
    // }
    return {
      trigger: 'axis',
      position,
    };
  },
  color: ['#4E81F8', '#5c28a9', '#48adb8', '#d741a7', '#F9A139'],
  grid,
  yAxis,
  yAxisArr: [
    {
      gridIndex: 0,
      ...yAxis,
    },
    {
      show: false,
      gridIndex: 1,
      scale: true,
      axisLabel: {
        ...yAxis.axisLabel,
        showMaxLabel: false,
      },
    },
  ],
  xAxis,
  xAxisArr: (dates, intervalL) => [
    {
      gridIndex: 0,
      type: 'category',
      ...xAxis(dates, intervalL),
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    {
      type: 'category',
      gridIndex: 1,
      ...xAxis(dates, intervalL),
    },
  ],
  legend: (data) => {
    if (isMobile()) {
      return {
        data,
        right: '5%',
        left: '15%',
      };
    } else {
      return {
        data,
      };
    }
  },
  gridArr: [
    {
      ...grid,
      bottom: '25%',
    },
    {
      ...grid,
      top: '75%',
    },
  ],
  volumeSeries: {
    type: 'bar',
    itemStyle: {
      color: '#e0e0e0',
    },
    emphasis: {
      itemStyle: {
        color: '#999999',
      },
    },
  },
};

import {Dropdown, Menu, Table} from 'antd';
import React, {useCallback, useMemo} from 'react';
import {CaretDownOutlined} from '@ant-design/icons';
import utils from '../../../../common/utils';
import './styles.less';
import {TableRateItem} from '../../../components';
import {useHistory} from 'react-router-dom';
import {CUP, USD_SYMBOL} from '../../../common/config';
import {useTranslation} from 'react-i18next';
import {useMedia} from '../../../../hooks';
import V from '../../../../common/utils/valueUtils';
import {CHAIN, PROJECT_CATEGORY} from '../../../../common/constant';
const SCROLL = 1000;
const HomeTable = (props) => {
  const scrollX = useMedia(`(max-width:${SCROLL + 100}px)`);
  const history = useHistory();
  const {options, currentSymbol, onCurrentUnit, textOptions} = props;
  const {t} = useTranslation([
    'table',
    'home',
    'radio',
    'translation',
    'token stats',
  ]);
  const TableMenu = useMemo(() => {
    return (
      <Menu onClick={(item) => onCurrentUnit?.(textOptions[item.key])}>
        {textOptions?.map((item, index) => {
          return <Menu.Item key={index}>{item.label}</Menu.Item>;
        })}
      </Menu>
    );
  }, [onCurrentUnit, textOptions]);
  const thDropdown = useMemo(() => {
    let text = currentSymbol;
    if (currentSymbol === USD_SYMBOL) {
      text = `${t('common:locked')}(${currentSymbol})`;
    } else {
      text = `${currentSymbol} ${t('common:locked')}`;
    }
    return (
      <Dropdown overlay={TableMenu}>
        <span onClick={(e) => e.preventDefault()}>
          {text} <CaretDownOutlined />
        </span>
      </Dropdown>
    );
  }, [TableMenu, currentSymbol, t]);
  const arrSort = useCallback(
    (a, b, c) => {
      const bV =
        currentSymbol === 'USD'
          ? V.getTVL(b, currentSymbol)
          : V.getVL(b, currentSymbol);
      if (isNaN(bV) || bV === 0) return c === 'descend' ? 1 : -1;
      const aV =
        currentSymbol === 'USD'
          ? V.getTVL(a, currentSymbol)
          : V.getVL(a, currentSymbol).value;
      return bV - aV;
    },
    [currentSymbol],
  );
  const columns = useMemo(() => {
    return [
      {
        title: '#',
        fixed: 'left',
        key: '#',
        className: 'de-radar-date',
        dataIndex: 'date',
        render: (text, item, index) => {
          return (
            <>
              <span className="de-radar-cup">
                {CUP[index] || <span className="de-radar-placeholder" />}
              </span>
              <span className="de-radar-emoji">{index + 1}.</span>
            </>
          );
        },
      },
      {
        className: 'de-radar-name',
        fixed: 'left',
        title: t('name'),
        key: 'Name',
        render: (text, item) => {
          return item.name;
        },
      },
      {
        title: t('chain'),
        key: 'Chain',
        render: (text, item) => {
          return CHAIN[item.chain];
        },
      },
      {
        title: t('category'),
        key: 'Category',
        render: (text, item) => {
          return t(`radio:${PROJECT_CATEGORY[item?.category]}`);
        },
      },
      {
        width: 150,
        key: 'locked',
        title: () => {
          return thDropdown;
        },
        render: (text, item) => {
          const value =
            currentSymbol === 'USD'
              ? V.getTotalValueLocked(item, currentSymbol)
              : V.getValueLocked(item, currentSymbol);
          return <TableRateItem symbol={currentSymbol} {...value} />;
        },
        sorter: (a, b, c) => {
          const V = arrSort(a, b, c);
          if (isNaN(V)) return c === 'descend' ? -1 : 1;
          return V < 0 ? 1 : -1;
        },
      },
      {
        title: t('translation:Market Cap'),
        key: 'Market Cap',
        render: (text, item) => {
          return (
            <TableRateItem
              noConversion
              value={utils.usdValue(V.getMarketCap(item))}
              relative1Day={V.getMarketCapRate(item)}
            />
          );
        },
        sorter: (a, b, c) =>
          utils.homeSorter(V.getMarketCap(a), V.getMarketCap(b), c),
      },
      {
        title: t('translation:Price'),
        key: 'Price',
        render: (text, item) => {
          return (
            <TableRateItem
              noConversion
              value={utils.priceValue(V.getTokenPrice(item))}
              relative1Day={V.getTokenPriceRate(item)}
            />
          );
        },
        sorter: (a, b, c) =>
          utils.homeSorter(V.getTokenPrice(a), V.getTokenPrice(b), c),
      },
      // {
      //   title: t('home:24h Revenue'),
      //   key: '24h Revenue',
      //   render: (text, item) => {
      //     return (
      //       <TableRateItem
      //         noConversion
      //         value={utils.usdValue(V.getDayRevenue(item))}
      //         relative1Day={V.getDayRevenueRate(item)}
      //       />
      //     );
      //   },
      //   sorter: (a, b, c) =>
      //     utils.homeSorter(V.getDayRevenue(a), V.getDayRevenue(b), c),
      // },
      {
        title: t('home:24h Volume'),
        key: '24h Volume',
        render: (text, item) => {
          return (
            <TableRateItem
              noConversion
              value={utils.usdValue(V.getDayVolume(item))}
              relative1Day={V.getDayVolumeRate(item)}
            />
          );
        },
        sorter: (a, b, c) =>
          utils.homeSorter(V.getDayVolume(a), V.getDayVolume(b), c),
      },
      // {
      //   title: (
      //     <TooltipTitle
      //       title={t('P/E')}
      //       tip={t('token stats:P/E (Price to Earning) Tip')}
      //     />
      //   ),
      //   key: 'P/E',
      //   render: (text, item) => {
      //     return utils.tableValue(V.getPE(item), 4);
      //   },
      //   sorter: (a, b, c) => utils.homeSorter(V.getPE(a), V.getPE(b), c),
      // },
      // {
      //   title: (
      //     <TooltipTitle title={t('ROTA')} tip={t('token stats:ROTA Tip')} />
      //   ),
      //   key: 'ROTA',
      //   render: (text, item) => {
      //     return utils.getRota(V.getDayRevenue(item), V.getTVL(item, 'USD'));
      //   },
      //   sorter: (a, b, c) => {
      //     return utils.homeSorter(
      //       utils.getRotaValue(V.getDayRevenue(a), V.getTVL(a)),
      //       utils.getRotaValue(V.getDayRevenue(b), V.getTVL(b)),
      //       c,
      //     );
      //   },
      // },
    ];
  }, [arrSort, currentSymbol, t, thDropdown]);
  return (
    <Table
      className={!scrollX && 'de-radar-home-table'}
      showSorterTooltip={false}
      loading={!options}
      pagination={false}
      scroll={scrollX ? {x: SCROLL} : undefined}
      columns={columns}
      dataSource={options}
      onRow={(record) => {
        return {
          onClick: () => history.push(`/${record.id}`),
        };
      }}
    />
  );
};
export default HomeTable;

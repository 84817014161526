import {Menu, Button, Drawer, Select} from 'antd';
import React, {useCallback, useMemo, useState} from 'react';
import {UnorderedListOutlined} from '@ant-design/icons';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useLanguage} from '../../../i18n';
import {LOCAL_LANGUAGE_LIST, LOCAL_LANGUAGE} from '../../../i18n/config';
import {useMobile} from '../../../hooks';

const Nav = () => {
  const isMobile = useMobile();
  const [visible, setVisible] = useState(false);
  const onClose = useCallback(() => {
    setVisible(false);
  }, []);
  const [language, changeLanguage] = useLanguage();
  const {t} = useTranslation('nav');
  const {pathname} = useLocation();
  const MenuItem = useMemo(() => {
    return (
      <>
        <Menu.Item key="/">
          <Link to="/">{t('Rankings')}</Link>
        </Menu.Item>
        <Menu.Item key="/de-radar-lending">
          <Link to="/de-radar-lending">{t('Lending')}</Link>
        </Menu.Item>
        <Menu.Item key="/de-radar-income">
          <Link to="/de-radar-income">{t('Earn Income')}</Link>
        </Menu.Item>
        <Menu.Item key="/de-radar-project">
          <Link to="/de-radar-project">{t('Project')}</Link>
        </Menu.Item>
      </>
    );
  }, [t]);
  const TableMenu = useMemo(() => {
    return (
      <Menu
        onClick={(item) => {
          if (LOCAL_LANGUAGE_LIST.includes(item.key)) changeLanguage(item.key);
          onClose();
        }}
        className="header-mobile-nav"
        selectedKeys={[pathname, language]}
        mode="inline">
        {MenuItem}
        <Menu.SubMenu
          key="sub1"
          title={
            LOCAL_LANGUAGE.find((item) => item.language === language).title
          }>
          {LOCAL_LANGUAGE.map((item) => (
            <Menu.Item key={item.language}>{item.title}</Menu.Item>
          ))}
        </Menu.SubMenu>
      </Menu>
    );
  }, [MenuItem, changeLanguage, language, onClose, pathname]);
  return (
    <div className="de-radar-nav">
      {isMobile ? (
        <Button type="primary" onClick={() => setVisible(!visible)}>
          <UnorderedListOutlined className="header-nav-icon" />
        </Button>
      ) : (
        <>
          <Menu id="header-nav" selectedKeys={[pathname]} mode="horizontal">
            {MenuItem}
          </Menu>
          <Select
            style={{width: '100px'}}
            value={language}
            onChange={changeLanguage}>
            {LOCAL_LANGUAGE.map((item) => (
              <Select.Option key={item.language} value={item.language}>
                {item.title}
              </Select.Option>
            ))}
          </Select>
        </>
      )}
      <Drawer
        className="top-drawer"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}>
        {TableMenu}
      </Drawer>
    </div>
  );
};

export default Nav;

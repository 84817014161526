// const list = [
//   {
//     module: 'Project',
//     key: 'Project_Token',
//   },
//   {
//     module: 'Project',
//     key: 'Project_Website',
//   },
//   {
//     module: 'Project',
//     key: 'Project_Links',
//   },
//   {
//     module: 'Project',
//     key: 'Project_DescriptionEN',
//   },
//   {
//     module: 'Project',
//     key: 'Project_DescriptionZH',
//   },
//   {
//     module: 'Project',
//     key: 'Project_ManualEN',
//   },
//   {
//     module: 'Project',
//     key: 'Project_ManualZH',
//   },
//   {
//     module: 'Snapshot',
//     key: 'Snapshot_MaxSupply',
//   },
//   {
//     module: 'Snapshot',
//     key: 'Snapshot_TotalSupply',
//   },
//   {
//     module: 'Snapshot',
//     key: 'Snapshot_CirculatingSupply',
//   },
//   {
//     module: 'Snapshot',
//     key: 'Snapshot_TokenPrice',
//   },
//   {
//     module: 'Snapshot',
//     key: 'Snapshot_Outstanding_{Symbol}',
//   },
//   {
//     module: 'Snapshot',
//     key: 'Snapshot_{ProjectModule}_ValueLocked_{Symbol}',
//   },
//   {
//     module: 'Snapshot',
//     key: 'Snapshot_{ProjectModule}_IncomeAPY_{Symbol}',
//   },
//   {
//     module: 'Snapshot',
//     key: 'Snapshot_{ProjectModule}_PayoutAPY_{Symbol}',
//   },
//   {
//     module: 'Snapshot',
//     key: 'Snapshot_{ProjectModule}_RevenueAmount',
//   },
//   {
//     module: 'Snapshot',
//     key: 'Snapshot_{ProjectModule}_VolumeAmount',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_MaxSupply',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_TotalSupply',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_CirculatingSupply',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_TokenPrice',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_Outstanding_{Symbol}',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_{ProjectModule}_ValueLocked_{Symbol}',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_{ProjectModule}_IncomeAPY_{Symbol}',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_{ProjectModule}_PayoutAPY_{Symbol}',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_{ProjectModule}_RevenueAmount',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_{ProjectModule}_VolumeAmount',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_TotalVolume',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_TotalRevenue',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_TotalValueLocked_USD',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_TotalValueLocked_BTC',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_TotalValueLocked_ETH',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_ValueLocked_{Symbol}',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_DayTokenIssuance',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_MarketCap',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_MarketCapRate',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_PE',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_DayRevenueRate',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_DayVolumeRate',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_TokenPriceRate',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_DayRevenue',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_MonthRevenue',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_YearRevenue',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_DayVolume',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_WeekVolume',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_ValueLockedRate_{Symbol}',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_TotalValueLockedRate_USD',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_TotalValueLockedRate_BTC',
//   },
//   {
//     module: 'SummarySnapshot',
//     key: 'SummarySnapshot_TotalValueLockedRate_ETH',
//   },
//   //首页图表
//   {Module: 'CategorySnapshot', Key: 'CategorySnapshot.TotalValueLocked.USD'},
//   {Module: 'CategorySnapshot', Key: 'CategorySnapshot.ValueLocked.BTC'},
//   {Module: 'CategorySnapshot', Key: 'CategorySnapshot.ValueLocked.ETH'},
// ];
export default {
  //Module
  SummarySnapshot: 'SummarySnapshot',
  CategorySnapshot: 'CategorySnapshot',
  //Key
  VL: 'ValueLocked',
  VLRate: 'ValueLockedRate',
  TVLRate: 'TotalValueLockedRate',
  TVL: 'TotalValueLocked',
  PE: 'PE',
  MarketCap: 'MarketCap',
  MarketCapRate: 'MarketCapRate',
  TokenPrice: 'TokenPrice',
  TokenPriceRate: 'TokenPriceRate',
  MonthProfit: 'MonthProfit',
  YearProfit: 'YearProfit',
  DayProfitRate: 'DayProfitRate',
  DayVolume: 'DayVolume',
  WeekVolume: 'WeekVolume',
  DayVolumeRate: 'DayVolumeRate',
  MarketCapDominance: 'MarketCapDominance',
  TotalSupply: 'TotalSupply',
  MaxSupply: 'MaxSupply',
  CirculatingSupply: 'CirculatingSupply',
  DayTokenIssuance: 'DayTokenIssuance',

  //Module
  Project: 'Project',
  //Key
  Website: 'Website',
  Token: 'Token',
  Project_Token: 'Project_Token',
  DescriptionEN: 'DescriptionEN',
  DescriptionZH: 'DescriptionZH',
  ManualEN: 'ManualEN',
  ManualZH: 'ManualZH',
  IncomeAPY: 'IncomeAPY',
  PayoutAPY: 'PayoutAPY',
  LendingProject: 'SummarySnapshot_Lending',
  Lending_IncomeAPY: 'Lending_IncomeAPY',
  Lending_PayoutAPY: 'Lending_PayoutAPY',
  Outstanding: 'Outstanding',
  DayRevenue: 'DayRevenue',
  MonthRevenue: 'MonthRevenue',
  YearRevenue: 'YearRevenue',
  DayRevenueRate: 'DayRevenueRate',
};

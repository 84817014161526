const CHAIN = ['Bitcoin', 'Ethereum'];
const PROJECT_CATEGORY = [
  'None',
  'Lending',
  'DEXes',
  'Derivatives',
  'Payments',
  'Assets',
];
export {CHAIN, PROJECT_CATEGORY};

import React from 'react';
import {Link} from 'react-router-dom';
import './styles.less';
const NoData = () => {
  return (
    <div className="no-data">
      <h1>404 Error</h1>
      <h3>
        <Link to="/">Back to home</Link>
      </h3>
    </div>
  );
};

export default NoData;

import React from 'react';
import utils from '../../common/utils';
import './styles.less';
const RadioBox = (props) => {
  const {options, value, onChange} = props;
  return (
    <div className="radio-box">
      {options?.map((item, index) => {
        let incoming = [];
        if (item.value === value) {
          incoming.push('radio-selected');
        }
        return (
          <button
            key={index}
            onClick={() => onChange?.(item)}
            className={utils.getClassName('radio-item', incoming)}>
            {item.label}
          </button>
        );
      })}
    </div>
  );
};

export default RadioBox;

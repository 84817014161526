import React, {useCallback, useMemo, useState, useEffect} from 'react';
import utils from '../../common/utils';
import {OverviewCard, RadioTextBox} from '../../components';
import {
  lendingRadioOptionsArr,
  LendingRateOptionsArr,
  // useLendingOptions,
} from './config';
import LendingCharts from './components/LendingCharts';
import LendingTable from './components/LendingTable';
import LendingRadioBox from './components/LendingRadioBox';
import {Tabs} from 'antd';
import {USD_SYMBOL, useTIME_OPTIONS} from '../common/config';
import {useTranslation} from 'react-i18next';
import {useLendingContext} from '../../contexts/lendingContext';
import lendingUtils from '../../common/utils/lendingUtils';
import {useHomeContext} from '../../contexts/homeContext';
const {TabPane} = Tabs;
const deCurrentTime = '90';
const Lending = () => {
  const [{lendingProjects}] = useHomeContext();
  const [{lendingHistories}, {fetchLendingHistories}] = useLendingContext();
  const timeOptions = useTIME_OPTIONS();
  const {t} = useTranslation(['lending', 'common']);
  const lendingRateOptions = useMemo(() => {
    return LendingRateOptionsArr.map((item) => ({
      ...item,
      label: t(item.label),
    }));
  }, [t]);
  const lendingRadioOptions = useMemo(() => {
    return lendingRadioOptionsArr.map((item) => ({
      ...item,
      label: t(item.label),
    }));
  }, [t]);
  const [radio, setRadio] = useState(lendingRadioOptions[0]);
  const {value: radioValue, symbol: radioSymbol, label: radioLabel} = radio;
  const [currentTime, setCurrentTime] = useState(deCurrentTime);
  const [chartRadio] = useState('Rates');
  const [rate, setRateRadio] = useState(lendingRateOptions?.[0]);
  const {value: rateRadio, label: rateLabel} = rate;
  useEffect(() => {
    fetchLendingHistories(radioValue, currentTime, lendingProjects);
  }, [
    chartRadio,
    currentTime,
    fetchLendingHistories,
    lendingProjects,
    radioValue,
  ]);
  const [textOptions, radioOptions] = useMemo(() => {
    return utils.getLendingOptions(lendingProjects);
  }, [lendingProjects]);
  const onRadioChange = useCallback((item) => {
    item?.value && setRadio(item);
  }, []);
  const onRateRadioChange = useCallback((item) => {
    item?.value && setRateRadio(item);
  }, []);
  const onCurrentTimeChange = useCallback((item) => {
    item?.value && setCurrentTime(item?.value);
  }, []);
  const cardTitleText = useMemo(() => {
    let title = t('Interest Per Year');
    if (chartRadio === 'outstanding') {
      title = t('Debt Outstanding');
    } else if (chartRadio === 'Rates') {
      title = t('Minimum Borrow Rate');
    }
    if (radioSymbol !== USD_SYMBOL) {
      title = `${title}`;
    } else {
      title = `${title} IPY (${radioSymbol})`;
    }
    return title;
  }, [chartRadio, radioSymbol, t]);
  const titleText = useMemo(() => {
    if (chartRadio === 'Rates') {
      return t('chartRadio title', {
        radioLabel: utils.isChinese(t(radioLabel))
          ? t(radioLabel)
          : t(radioLabel) + ' ',
        rateLabel: t(rateLabel),
      });
    } else {
      return cardTitleText;
    }
  }, [cardTitleText, chartRadio, radioLabel, rateLabel, t]);
  const OverViewRadio = useMemo(() => {
    return (
      <div className="overview-radio">
        <RadioTextBox
          value={chartRadio === 'Rates' ? rateRadio : radioValue}
          onChange={chartRadio === 'Rates' ? onRateRadioChange : onRadioChange}
          options={
            chartRadio === 'Rates' ? lendingRateOptions : lendingRadioOptions
          }
        />
        <RadioTextBox
          value={currentTime}
          options={timeOptions}
          onChange={onCurrentTimeChange}
        />
      </div>
    );
  }, [
    chartRadio,
    currentTime,
    lendingRadioOptions,
    lendingRateOptions,
    onCurrentTimeChange,
    onRadioChange,
    onRateRadioChange,
    radioValue,
    rateRadio,
    timeOptions,
  ]);
  const OverViewCharts = useMemo(() => {
    return (
      <div className="overview-charts">
        <div className="overview-charts-charts-box">
          <h1>{titleText}</h1>
          {OverViewRadio}
          <LendingCharts
            rateRadio={rateRadio}
            lendingHistories={lendingHistories}
            chartRadio={chartRadio}
            radioValue={radioValue}
          />
        </div>
      </div>
    );
  }, [
    OverViewRadio,
    chartRadio,
    radioValue,
    lendingHistories,
    rateRadio,
    titleText,
  ]);
  const Overview = useMemo(() => {
    return (
      <>
        <h1>{t('lending')}</h1>
        <div className="overview">
          <div className="overview-left-box">
            {/* <OverviewCard
              title={t('Interest Per Year')}
              details={utils.usdValue(
                lendingUtils.getInterestSpeed(lendingHistories?.[0]),
              )}
            />
            <OverviewCard
              title={t('Debt Outstanding')}
              details={utils.usdValue(
                lendingUtils.getOutstanding(lendingHistories?.[0]),
              )}
            /> */}
            <OverviewCard
              title={t('Maximum Lend Rate')}
              details={utils.percentValue(
                lendingUtils.getMAXLend(lendingProjects, radioValue),
                4,
              )}
            />
            <OverviewCard
              title={t('Minimum Borrow Rate')}
              details={utils.percentValue(
                lendingUtils.getMINBorrow(lendingProjects, radioValue),
                4,
              )}
            />
          </div>
          <br />
          {/* <Tabs
            defaultActiveKey="1"
            type="card"
            size="default"
            onChange={setChartRadio}>
            {lendingOptions.map((item) => {
              return <TabPane tab={item.label} key={item.value} />;
            })}
          </Tabs> */}
          <TabPane tab={'Rates'} key={'Rates'} />
          {OverViewCharts}
        </div>
      </>
    );
  }, [t, lendingProjects, radioValue, OverViewCharts]);
  return (
    <>
      {Overview}
      <LendingRadioBox
        textOptions={textOptions}
        options={lendingRadioOptions}
        value={radioValue}
        onChange={onRadioChange}
      />
      <LendingTable
        radioSymbol={radioSymbol}
        textOptions={radioOptions}
        radioValue={radioValue}
        options={lendingProjects}
        onCurrentUnit={onRadioChange}
      />
    </>
  );
};

export default Lending;

const deAssets = {symbol: 'DAI', price: 0};

const deQuantity = '10000';
const maxQuantity = 100000000;
const minQuantity = 1;

const deStakingTime = '365';
const minStakingTime = 1;
const maxStakingTime = 36500;

//type
const incomeType = {
  Lending: 3,
  Farm: 2,
  Swap: 1,
  Vault: 4,
};
export default {
  incomeType,
  deQuantity,
  deStakingTime,
  maxQuantity,
  minQuantity,
  deAssets,
  minStakingTime,
  maxStakingTime,
};

import React, {useEffect} from 'react';
import utils from '../../../../common/utils';
import chartsConfig from '../../../common/chartsConfig';
import {useTranslation} from 'react-i18next';
import ECharts, {echarts} from '../../../../components/ECharts';
import {USD_SYMBOL} from '../../../common/config';
import V from '../../../../common/utils/valueUtils';
const formatter = (params) => {
  let nextText = '';
  Array.isArray(params) &&
    params.forEach((item) => {
      if (item.value !== undefined && item.value !== null) {
        nextText =
          nextText +
          '<br/>' +
          item.marker +
          item.seriesName +
          utils.unitConverter(item.value);
      }
    });
  return utils.formatTime(params[0].name) + nextText;
};
const formatter2 = (params) => {
  const param = params[0];
  let text = param.seriesName;
  if (param.seriesName === 'USD') {
    text = '$';
  }
  return (
    utils.formatTime(param.name) +
    '<br/>' +
    param.marker +
    text +
    ' ' +
    utils.unitConverter(param.value)
  );
};
const InfoCharts = (props) => {
  const {histories, currentUnit, currentSymbol, currentLabel} = props;
  const {t} = useTranslation();
  useEffect(() => {
    const init = () => {
      var myChart = echarts.init(document.getElementById('echarts-main'));
      let list;

      switch (currentUnit) {
        case USD_SYMBOL:
          list = V.getTVL(histories, currentUnit);
          break;
        case 'Market Cap':
          list = V.getMarketCap(histories);
          break;
        case 'Revenue':
          list = V.getDayRevenue(histories);
          break;
        default:
          list = V.getVL(histories, currentUnit);
          break;
      }
      let data = [],
        dates = [],
        volume = [],
        tim = [];
      let options;
      if (currentUnit === 'Market Cap' || currentUnit === 'Revenue') {
        let obj = {};
        const Volume = V.getDayVolume(histories);
        for (let i = 0, j = list.length; i < j; i++) {
          const element = list[i];
          if (!element) continue;
          const {timestamp} = element;
          if (!obj[timestamp]) obj[timestamp] = {};
          obj[timestamp].value = element.value;
          data.push(utils.chartsItem(element.value));
          dates.push(utils.getChartsTime(element.timestamp));
          tim.push(element.timestamp);
        }
        if (Array.isArray(Volume)) {
          for (let i = 0, j = Volume.length; i < j; i++) {
            const element = Volume[i];
            if (!element) continue;
            const {timestamp} = element;
            if (!obj[timestamp]) continue;
            volume.push(utils.chartsItem(element?.value));
            obj[timestamp].volume = element.value;
          }
          if (
            !obj[tim[tim.length - 1]]?.volume &&
            utils.isToday(Volume[Volume.length - 2].timestamp)
          ) {
            volume.push(utils.chartsItem(Volume[Volume.length - 1].value));
          }
        }
        let series = [];
        series.push({
          name: `${currentLabel} $`,
          type: 'line',
          data: data,
          xAxisIndex: 0,
          yAxisIndex: 0,
        });
        if (volume.length) {
          series.push({
            name: `${t('Volume')} $`,
            ...chartsConfig.volumeSeries,
            data: volume,
            xAxisIndex: 1,
            yAxisIndex: 1,
          });
        }

        options = {
          color: chartsConfig.color,
          series,
          ...(series.length === 1
            ? {
                tooltip: {
                  ...chartsConfig.tooltip(),
                  formatter: formatter2,
                },
                grid: chartsConfig.grid,
                xAxis: chartsConfig.xAxis(dates, 8),
                yAxis: chartsConfig.yAxis,
              }
            : {
                tooltip: {
                  ...chartsConfig.tooltip(),
                  formatter,
                },
                axisPointer: {
                  link: {xAxisIndex: 'all'},
                },
                xAxis: chartsConfig.xAxisArr(dates, 8),
                yAxis: chartsConfig.yAxisArr,
                grid: chartsConfig.gridArr,
              }),
        };
        myChart.setOption(options, true);
      } else if (Array.isArray(list)) {
        for (let i = 0, j = list.length; i < j; i++) {
          const element = list[i];
          if (!element) continue;
          dates.push(utils.getChartsTime(element.timestamp));
          data.push(utils.chartsItem(element?.value));
        }
        options = {
          color: chartsConfig.color,
          tooltip: {
            ...chartsConfig.tooltip(),
            formatter: formatter2,
          },
          grid: chartsConfig.grid,
          xAxis: chartsConfig.xAxis(dates, 8),
          yAxis: chartsConfig.yAxis,
          series: [
            {
              name: currentSymbol,
              type: 'line',
              data: data,
            },
          ],
        };
        myChart.setOption(options, true);
      }
    };
    init();
    window.addEventListener('resize', init);
    return () => window.removeEventListener('resize', init);
  }, [currentLabel, currentSymbol, currentUnit, histories, t]);
  return <ECharts loading={!histories} />;
};

export default InfoCharts;

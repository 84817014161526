import {Button, Col, Dropdown, InputNumber, Menu, Row} from 'antd';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {CaretDownOutlined} from '@ant-design/icons';
import {useIncomeContext} from '../../../../contexts/incomeContext';
import config from '../../config';
import {usePrevious} from '../../../../hooks';
import utils from '../../../../common/utils';
const timeFun = (callBack, e) => {
  return setTimeout(() => {
    callBack?.(e);
  }, 500);
};
const IncomeTool = (props) => {
  const {
    onSetAssets,
    assets,
    onSetQuantity,
    onSetStakingTime,
    onSetPrice,
  } = props;
  const prevAssets = usePrevious(assets);
  const timer = useRef();
  const setQuantity = useCallback(
    (e) => {
      if (isNaN(e)) return;
      timer.current && clearTimeout(timer.current);
      timer.current = timeFun(onSetQuantity, e);
    },
    [onSetQuantity],
  );
  const setStakingTime = useCallback(
    (e) => {
      if (isNaN(e)) return;
      timer.current && clearTimeout(timer.current);
      timer.current = timeFun(onSetStakingTime, e);
    },
    [onSetStakingTime],
  );
  const setPrice = useCallback(
    (e) => {
      setStatePrice(e);
      if (isNaN(e)) return;
      timer.current && clearTimeout(timer.current);
      timer.current = timeFun(onSetPrice, e);
    },
    [onSetPrice],
  );
  const [{lendingToken, tokens}, {fetchTokenPrice}] = useIncomeContext();
  const [price, setStatePrice] = useState(tokens?.[assets]);
  useEffect(() => {
    if (prevAssets !== assets || (!price && tokens?.[assets])) {
      const p = utils.digits(tokens?.[assets], 2) || 0;
      onSetPrice?.(p);
      setStatePrice(p);
    }
  }, [assets, onSetPrice, prevAssets, price, tokens]);

  useEffect(() => {
    fetchTokenPrice(assets);
    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, [assets, fetchTokenPrice]);
  const {t} = useTranslation('income');
  const dropdownMenu = useMemo(() => {
    return (
      <div className="dropdown-menu">
        <Menu onClick={(item) => onSetAssets(lendingToken[item.key])}>
          {lendingToken?.map((item, index) => {
            return <Menu.Item key={index}>{item?.symbol}</Menu.Item>;
          })}
        </Menu>
      </div>
    );
  }, [lendingToken, onSetAssets]);
  return (
    <Row className="income-tool">
      <Col>
        {t('Assets')}:&nbsp;&nbsp;
        <Dropdown
          className="income-dropdown"
          overlay={dropdownMenu}
          trigger={['click']}>
          <Button onClick={(e) => e.preventDefault()}>
            {assets} <CaretDownOutlined />
          </Button>
        </Dropdown>
      </Col>
      <Col>
        {t('Quantity')}:&nbsp;&nbsp;
        <InputNumber
          onChange={setQuantity}
          className="income-input"
          defaultValue={config.deQuantity}
          max={config.maxQuantity}
          min={config.minQuantity}
        />
      </Col>
      <Col>
        {t('Assets Price')}:&nbsp;&nbsp;
        <InputNumber
          step={0.01}
          value={price}
          onChange={setPrice}
          formatter={(value) => `$${value}`}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          className="income-input"
        />
      </Col>
      <Col>
        {t('Staking Time')}:&nbsp;&nbsp;
        <InputNumber
          formatter={(value) => `${value}${t('Days')}`}
          parser={(value) =>
            value
              .replace(t('Days'), '')
              .replace('Day', '')
              .replace('Da', '')
              .replace('D', '')
          }
          onChange={setStakingTime}
          className="income-input"
          defaultValue={config.deStakingTime}
          max={config.maxStakingTime}
          min={config.minStakingTime}
        />
      </Col>
    </Row>
  );
};
export default memo(IncomeTool);

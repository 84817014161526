import React, {memo} from 'react';
import utils from '../../../common/utils';
import {RateSpan} from '../../../components';
import {USD_SYMBOL} from '../../common/config';

export default memo(function ({
  value,
  relative1Day,
  symbol,
  noConversion,
  price,
}) {
  if (!value || value === '-') return '-';
  return (
    <span>
      {symbol === USD_SYMBOL ? '$' : ''}
      {noConversion
        ? value
        : price
        ? utils.priceValue(value)
        : utils.unitConverter(value)}
      &nbsp;
      <RateSpan>{relative1Day}</RateSpan>
    </span>
  );
});

const HOME_CHART_KEY = [
  'CategorySnapshot_TotalValueLocked_USD',
  'CategorySnapshot_ValueLocked_BTC',
  'CategorySnapshot_ValueLocked_ETH',
];
const LENDING_CHART_KEY = [
  'SummarySnapshot_{ProjectModuleType}_IncomeAPY_{Symbol}',
  'SummarySnapshot_{ProjectModuleType}_PayoutAPY_{Symbol}',
];
const INFO_CHART_KEY = [
  'SummarySnapshot_MarketCap',
  'SummarySnapshot_DayRevenue',
  'SummarySnapshot_DayVolume',
  'SummarySnapshot_TotalValueLocked_USD',
];
const INFO_TABLE_KEY = [
  ...INFO_CHART_KEY,
  'SummarySnapshot_PE',
  'SummarySnapshot_MarketCapRate',
  'SummarySnapshot_DayRevenueRate',
  'SummarySnapshot_DayVolumeRate',
  'SummarySnapshot_TokenPrice',
  'SummarySnapshot_TokenPriceRate',
  'SummarySnapshot_TotalValueLockedRate_USD',
];
export default {
  HOME_CHART_KEY,
  LENDING_CHART_KEY,
  INFO_CHART_KEY,
  INFO_TABLE_KEY,
};

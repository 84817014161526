import React, {useEffect} from 'react';
import utils from '../../../../common/utils';
import chartsConfig from '../../../common/chartsConfig';
import {useTranslation} from 'react-i18next';
import ECharts, {echarts} from '../../../../components/ECharts';
import lendingUtils from '../../../../common/utils/lendingUtils';
import V from '../../../../common/utils/valueUtils';
const rateFormatter = (params) => {
  let nextText = '';
  Array.isArray(params) &&
    params.forEach((item) => {
      if (item.value !== undefined && item.value !== null) {
        nextText =
          nextText +
          '<br/>' +
          item.marker +
          item.seriesName +
          ':  ' +
          utils.percentValue(item.value, 4);
      }
    });
  return utils.formatTime(params[0].name) + nextText;
};
const formatter = (params, t) => {
  const param = params[0];
  return (
    utils.formatTime(param.name) +
    '<br/>' +
    param.marker +
    `${param.seriesName === 'outstanding' ? t('Outstanding') : 'IPY'} $` +
    utils.unitConverter(param.value)
  );
};
const LendingCharts = (props) => {
  const {t} = useTranslation('lending radio');
  const {radioValue, chartRadio, lendingHistories, rateRadio} = props;
  useEffect(() => {
    var myChart = echarts.init(document.getElementById('echarts-main'));
    if (chartRadio === 'Rates') {
      const list = lendingHistories?.[radioValue];
      if (Array.isArray(list)) {
        let series = [],
          dates = [],
          legendData = [],
          names = [],
          obj = {};

        list.forEach((i) => {
          Object.entries(i || {}).forEach(([k, v]) => {
            const histories =
              rateRadio === 'payoutAPY'
                ? V.getLendingPayoutAPY(v, radioValue)
                : V.getLendingIncomeAPY(v, radioValue);
            if (Array.isArray(histories)) {
              for (let i = 0, j = histories.length; i < j; i++) {
                const {timestamp, value} = histories[i];
                if (!obj[timestamp]) obj[timestamp] = {};
                if (!obj[timestamp][k]) obj[timestamp][k] = {};
                obj[timestamp][k] = value;
              }
              names.push(k);
            }
          });
        });
        const newList = Object.entries(obj);
        names.forEach((name) => {
          let data = [];
          let bool = false;
          for (let i = 0, j = newList.length; i < j; i++) {
            const [time, value] = newList[i];
            if (!dates.includes(time) && value[name]) dates.push(time);
            if (value[name] && !bool) bool = true;
            data.push(value[name]);
          }
          if (bool) {
            legendData.push(name);
            series.push({
              type: 'line',
              data,
              name: name,
              symbol: 'none',
            });
          }
        });
        const option = {
          color: chartsConfig.color,
          tooltip: {
            ...chartsConfig.tooltip(),
            formatter: rateFormatter,
          },
          legend: chartsConfig.legend(legendData),
          grid: {
            ...chartsConfig.grid,
            top: '7%',
          },
          xAxis: chartsConfig.xAxis(dates),
          yAxis: {
            ...chartsConfig.yAxis,
            axisLabel: {
              ...chartsConfig.yAxis.axisLabel,
              formatter: function (value) {
                return value + '%';
              },
            },
          },
          series,
        };
        myChart.setOption(option, true);
      }
    } else {
      if (Array.isArray(lendingHistories)) {
        let data = [],
          dates = [];
        for (let i = lendingHistories.length - 1; i >= 0; i--) {
          const element = lendingHistories[i];
          const v =
            chartRadio === 'outstanding'
              ? lendingUtils.getOutstanding(element)
              : lendingUtils.getInterestSpeed(element);
          dates.push(element.timestamp);
          data.push(v);
        }
        myChart.setOption(
          {
            color: chartsConfig.color,
            tooltip: {
              ...chartsConfig.tooltip(),
              formatter: (params) => formatter(params, t),
            },
            grid: chartsConfig.grid,
            xAxis: chartsConfig.xAxis(dates),
            yAxis: chartsConfig.yAxis,
            series: [
              {
                name: chartRadio,
                type: 'line',
                data: data,
              },
            ],
          },
          true,
        );
      }
    }
  }, [radioValue, chartRadio, lendingHistories, rateRadio, t]);
  return <ECharts loading={!lendingHistories} />;
};

export default LendingCharts;

import {useTranslation} from 'react-i18next';
const hideRadioOptions = [
  'COMPOSITE',
  'WETH',
  'DAI',
  'USDC',
  'USDT',
  'ETH',
  'BTC',
  'TUSD',
];

const lendingRadioOptionsArr = [
  // {label: 'COMPOSITE', value: 'COMPOSITE', symbol: 'COMPOSITE'},
  {label: 'DAI', value: 'DAI', symbol: 'DAI'},
  {label: 'USDC', value: 'USDC', symbol: 'USDC'},
  {label: 'USDT', value: 'USDT', symbol: 'USDT'},
  {label: 'ETH', value: 'ETH', symbol: 'ETH'},
  {label: 'TUSD', value: 'TUSD', symbol: 'TUSD'},
];

const useLendingOptions = () => {
  const {t} = useTranslation('lending radio');
  return [
    // {label: t('IPY'), value: 'interestSpeed'},
    // {label: t('Outstanding'), value: 'outstanding'},
    {label: t('Rates'), value: 'Rates'},
  ];
};
const LendingRateOptionsArr = [
  {label: 'Borrow', value: 'payoutAPY'},
  {label: 'Lend', value: 'incomeAPY'},
];
export {
  lendingRadioOptionsArr,
  hideRadioOptions,
  useLendingOptions,
  LendingRateOptionsArr,
};

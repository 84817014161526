import utils from '.';

function getMaxIncomeAndMinPayout(incomeData: any) {
  let maxIncome = 0,
    minPayout = 0;
  Array.isArray(incomeData) &&
    incomeData.forEach((i) => {
      const {totalIncomeValue, totalPayoutValue} = i || {};
      if (maxIncome < totalIncomeValue) maxIncome = totalIncomeValue;
      if (minPayout === 0 || minPayout > totalPayoutValue)
        minPayout = totalPayoutValue;
    });
  return {maxIncome, minPayout};
}

function usdValue(v: any) {
  if (isNaN(v) || v === 0) return '$0';
  return '$' + utils.unitConverter(v);
}
export default {getMaxIncomeAndMinPayout, usdValue};

import React, {useMemo, useState} from 'react';
import IncomeTable from '../components/IncomeTable';
import {useTranslation} from 'react-i18next';
import IncomeTool from '../components/IncomeTool';
import config from '../config';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import incomeUtils from '../../../common/utils/incomeUtils';
import {Tooltip} from 'antd';
import V from '../../../common/utils/valueUtils';
import {useHomeContext} from '../../../contexts/homeContext';
const IncomePage = ({type, module}) => {
  const {t} = useTranslation('income');
  const [{lendingProjects}] = useHomeContext();
  const [token, setAssets] = useState(config.deAssets);
  const {symbol: assets} = token;
  const [quantity, setQuantity] = useState(config.deQuantity);
  const [stakingTime, setStakingTime] = useState(config.deStakingTime);
  const [price, setPrice] = useState(undefined);
  const dataSource = useMemo(() => {
    let list;
    if (Array.isArray(lendingProjects)) {
      list = [];
      lendingProjects.forEach((i) => {
        const incomeAPY = V.getIncomeAPY(i, module, assets);
        const payoutAPY = V.getPayoutAPY(i, module, assets);
        if (incomeAPY || payoutAPY) {
          const totalPayout = (payoutAPY / 365 / 100) * quantity * stakingTime;
          const totalIncome = (incomeAPY / 365 / 100) * quantity * stakingTime;
          list.push({
            key: i?.name,
            name: i?.name,
            assets,
            payoutAPY,
            incomeAPY,
            totalPayout,
            totalIncome,
            totalPayoutValue: totalPayout * price,
            totalIncomeValue: totalIncome * price,
            website: i?.website,
            id: i?.id,
          });
        }
      });
    }
    return list;
  }, [lendingProjects, module, assets, quantity, stakingTime, price]);
  const Overview = useMemo(() => {
    const {maxIncome, minPayout} = incomeUtils.getMaxIncomeAndMinPayout(
      dataSource,
    );
    return (
      <div className="overview">
        <div className="overview-left-box income-tip-box">
          <span>
            <Tooltip
              className="income-tip-icon"
              placement="bottomLeft"
              title={t('income tip')}>
              <ExclamationCircleOutlined />
            </Tooltip>
            {t('Current Staking Value')}:&nbsp;
            {incomeUtils.usdValue(quantity * price)} ({quantity} {assets})
          </span>
          <span>
            {t('Maximum Total Income')}: {incomeUtils.usdValue(maxIncome)}
          </span>
          {type === config.incomeType.Lending ? (
            <span>
              {t('Minimum Total Payout')}: {incomeUtils.usdValue(minPayout)}
            </span>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, t]);
  return (
    <>
      <IncomeTool
        onSetPrice={setPrice}
        onSetStakingTime={setStakingTime}
        onSetAssets={setAssets}
        assets={assets}
        onSetQuantity={setQuantity}
      />
      {Overview}
      <IncomeTable
        dataSource={dataSource}
        assets={assets}
        type={type}
        stakingTime={stakingTime}
      />
    </>
  );
};

export default IncomePage;

import React from 'react';
import utils from '../../common/utils';
import './styles.less';
const RadioTextBox = (props) => {
  const {options, value, onChange} = props;
  return (
    <div className="radio-text-box">
      {options?.map((item, index) => {
        let incoming = [];
        if (item.value === value) {
          incoming.push('radio-text-selected');
        }
        let element;
        if (options.length - 1 !== index) {
          element = <span className="radio-text-item margin-item"> | </span>;
        }
        return (
          <div key={index}>
            <span
              onClick={() => onChange?.(item)}
              className={utils.getClassName('radio-text-item', incoming)}>
              {item.label}
            </span>
            {element}
          </div>
        );
      })}
    </div>
  );
};

export default RadioTextBox;

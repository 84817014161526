import React from 'react';
import {Layout} from 'antd';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import '../common/App.less';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/legend';
import '../i18n/index';
import '../common/console.log';
import TopBar from './TopBar';
import DeRadarFooter from './DeRadarFooter';
import Home from './Home';
import Lending from './Lending';
import DeRadarInfo from './DeRadarInfo';
import Income from './Income';
import DeRadarProject from './DeRadarProject';
import {NoData, ScrollToTop} from './components';
const {Content} = Layout;
import {ConfigProvider} from 'antd';
import {useLanguage} from '../i18n';
import {ANTD_LOCAL} from '../i18n/config';
import HomeStorageContextProvider from '../contexts/homeContext';
import LendingStorageContextProvider from '../contexts/lendingContext';
import IncomeStorageContextProvider from '../contexts/incomeContext';
function ContextProviders({children}) {
  return (
    <HomeStorageContextProvider>
      <LendingStorageContextProvider>
        <IncomeStorageContextProvider>{children}</IncomeStorageContextProvider>
      </LendingStorageContextProvider>
    </HomeStorageContextProvider>
  );
}
function Routes() {
  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/de-radar-lending" exact>
        <Lending />
      </Route>
      <Route path="/de-radar-income" exact>
        <Income />
      </Route>
      <Route path="/de-radar-project" exact>
        <DeRadarProject />
      </Route>
      <Route path="/:projectId" exact>
        <DeRadarInfo />
      </Route>
      {/* catch 404 error. always matches */}
      <Route path="*" exact>
        <NoData />
      </Route>
    </Switch>
  );
}
function App() {
  const [language] = useLanguage();
  return (
    <ConfigProvider locale={ANTD_LOCAL[language]}>
      <ContextProviders>
        <Layout className="layout">
          <Router>
            <ScrollToTop />
            <TopBar />
            <Content className="content">
              <Routes />
            </Content>
            <DeRadarFooter />
          </Router>
        </Layout>
      </ContextProviders>
    </ConfigProvider>
  );
}
export default App;
